import { Box, Text } from "@chakra-ui/react";
import { MapPin } from "@phosphor-icons/react";
import { Handle, Position } from "reactflow";

import { colors } from "../../../../styles/chakra-theme-v2";
import { createResourceRoute } from "../../../../utils/createResourceRoute";
import { singularize } from "../../../../utils/english";
import { IconWithText } from "../../IconWithText";
import { Link } from "../../Link";
import { OverflownText } from "../../OverflownText";
import { ResourceIcon } from "../../ResourceIcon";
import { ResourceStatusBadge } from "../../ResourceStatus/ResourceStatusBadge";
import {
  GRAPH_LAYOUT,
  NODE_BORDER_COLOR,
  NODE_BORDER_COLOR_CURRENT,
  NODE_HEIGHT,
  NODE_WIDTH,
} from "../config";
import { ResourceNodeData } from "../types";
import { ResourceNodeTeaser } from "./ResourceNodeTeaser";

/**
 * Renders a node in the resource graph.
 *
 * Handles are visually hidden since we rely on edge markers to style the connections
 * and handle changing those marker styles when hovering nodes.
 */
export const ResourceNode = ({ data }: { data: ResourceNodeData }) => {
  const resourceLinkProps = createResourceRoute({
    id: data.id,
    type: data.type,
    upstreamId: data.upstreamId,
  });

  const columnGroup = GRAPH_LAYOUT.find((g) => g.types.includes(data.type));

  if (!columnGroup) {
    // should not happen - TS hand holding
    throw new Error(`No column group found for resource type ${data.type}`);
  }

  const Icon = columnGroup.icon ?? ResourceIcon[data.type];

  return (
    <Link
      routeName={resourceLinkProps.routeName}
      params={resourceLinkProps.params}
    >
      <Handle
        type="target"
        position={Position.Left}
        style={{ visibility: "hidden" }}
      />
      <Box
        sx={{
          width: NODE_WIDTH,
          height: NODE_HEIGHT,
          bg: colors.white,
          borderRadius: 8,
          p: 3,
          border: "2px solid",
          borderColor: data.current
            ? NODE_BORDER_COLOR_CURRENT
            : NODE_BORDER_COLOR,
          transition: "border-color 50ms ease-in-out",
          _hover: {
            borderColor: "primary",
          },
        }}
      >
        <div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 1,
              fontSize: "fdy_sm",
            }}
          >
            <IconWithText>
              <Icon />
              {singularize(columnGroup.label)}
            </IconWithText>
            {data.archived ? (
              "Archived"
            ) : (
              <ResourceStatusBadge
                resource={{
                  id: data.id,
                  status: data.status,
                  status_error: data.statusError,
                  resource_type: data.type,
                  last_read_input_at: data.lastReadInputAt,
                  last_updated_config_at: data.lastUpdatedConfigAt,
                  last_updated_output_at: data.lastUpdatedOutputAt,
                }}
                readyAsTimestamp
              />
            )}
          </Box>
          <Text
            sx={{
              fontWeight: 500,
              color: "primary",
              fontSize: "fdy_md",
            }}
          >
            <OverflownText>{data.name}</OverflownText>
          </Text>
        </div>
        <ResourceNodeTeaser id={data.id} type={data.type} />
      </Box>

      {data.current && (
        <Text
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            fontSize: "fdy_sm",
            position: "absolute",
            top: "100%",
            paddingTop: 1,
          }}
        >
          <MapPin /> Current resource
        </Text>
      )}

      <Handle
        type="source"
        position={Position.Right}
        style={{ visibility: "hidden" }}
      />
    </Link>
  );
};
