import { ResourceType } from "@fdy/faraday-js";
import { ColumnDef } from "@tanstack/react-table";
import { useMemo } from "react";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { useSojournerQuery } from "../../../services/sojournerApolloClient";
import { cohortsListPageQuery } from "../../cohorts/CohortsListPage";
import { CohortsListPageQuery } from "../../cohorts/CohortsListPage/__generated__/CohortsListPageQuery";
import { ResourceLink } from "../../ui/ResourceLink";
import { ResourceStatusBadge } from "../../ui/ResourceStatus/ResourceStatusBadge";
import { ResourceTable } from "../../ui/ResourceTable";
import { TimeAgo } from "../../ui/TimeAgo";
import {
  OutcomesQuery,
  OutcomesQuery_outcomes as Outcome,
} from "../__generated__/OutcomesQuery";
import { outcomeEligibleCohortName } from "../outcomeEligibleCohortName";
import { OutcomeActionsMenu } from "../OutcomesActionsMenu";
import { outcomesQuery } from "../outcomesQuery";
import { OutcomeGradeBadge } from "../OutcomesShowPage/OutcomePerformanceCard/OutcomeGradeBadge";
import { cohortUsesFirstPartyData } from "../OutcomesShowPage/useOutcomeUsesFirstPartyData";

const BLANK_SLATE_PROPS = {
  title: "No outcomes found",
  text: "Outcomes show you the propensity of US consumers to take an action (as defined by a Cohort).",
  button: {
    routeName: ROUTE_NAMES.COHORTS,
    children:
      "Use Cohorts to create groups of US consumers to be used in an Outcome.",
  },
};

export function OutcomesTable() {
  const { data, loading, error } = useSojournerQuery<OutcomesQuery>(
    outcomesQuery,
    {
      pollInterval: 10000,
    }
  );
  if (error) throw error;

  const {
    data: cohortsData,
    loading: cohortsLoading,
    error: cohortsError,
  } = useSojournerQuery<CohortsListPageQuery>(cohortsListPageQuery);
  if (cohortsError) throw cohortsError;

  const tableColumns: ColumnDef<Outcome>[] = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        size: 200,
        cell: ({ row }) => (
          <ResourceLink resource={row.original} sx={{ fontWeight: "bold" }} />
        ),
      },
      {
        id: "performance",
        header: "Performance",
        cell: ({ row }) => {
          const outcome = row.original;
          const eligibleCohort = cohortsData?.cohorts.find(
            (c) => c.id === outcome.eligibleCohortId
          );
          const usesFPD = eligibleCohort
            ? cohortUsesFirstPartyData(eligibleCohort)
            : false;

          return outcome.rocAuc ? (
            <OutcomeGradeBadge
              usesFPD={usesFPD}
              rocAuc={outcome.rocAuc}
              liftValue={outcome.liftValue}
            />
          ) : null;
        },
      },
      {
        accessorFn: (row) => outcomeEligibleCohortName(row),
        header: "Eligibility",
        cell: ({ row }) => {
          const eligibleCohort = cohortsData?.cohorts.find(
            (c) => c.id === row.original.eligibleCohortId
          );
          return eligibleCohort ? (
            <ResourceLink resource={eligibleCohort} />
          ) : (
            "Anyone"
          );
        },
      },
      {
        accessorKey: "attainmentCohortName",
        header: "Attainment",
        cell: ({ row }) => {
          const attainmentCohort = cohortsData?.cohorts.find(
            (c) => c.id === row.original.attainmentCohortId
          );
          return attainmentCohort ? (
            <ResourceLink resource={attainmentCohort} />
          ) : null;
        },
      },
      {
        accessorKey: "attritionCohortName",
        header: "Attrition",
        cell: ({ row }) => {
          const attritionCohort = cohortsData?.cohorts.find(
            (c) => c.id === row.original.attritionCohortId
          );
          return attritionCohort ? (
            <ResourceLink resource={attritionCohort} />
          ) : null;
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: ({ row }) => (
          <ResourceStatusBadge
            resource={row.original}
            titlePrefix={row.original.preview ? "Preview" : ""}
          />
        ),
        sortingFn: "basic",
      },
      {
        accessorKey: "createdAt",
        header: "Created",
        cell: ({ row }) => <TimeAgo date={row.original.createdAt} />,
        sortingFn: "datetime",
      },
      {
        accessorKey: "lastUpdatedConfigAt",
        header: "Modified",
        cell: ({ row }) => <TimeAgo date={row.original.lastUpdatedConfigAt} />,
        sortingFn: "datetime",
      },
      {
        accessorKey: "lastUpdatedOutputAt",
        header: "Processed",
        cell: ({ row }) => <TimeAgo date={row.original.lastUpdatedOutputAt} />,
        sortingFn: "datetime",
      },
      {
        id: "actions",
        header: "Actions",
        size: 64,
        enableSorting: false,
        cell: ({ row }) => (
          <OutcomeActionsMenu
            outcome={row.original}
            options={{
              view: true,
              technical_report: true,
              rename: true,
              edit: true,
              api: true,
              delete: true,
              archive: true,
            }}
            buttonProps={{ variant: "icon", my: -2 }}
          />
        ),
      },
    ],
    [cohortsData]
  );

  return (
    <ResourceTable<Outcome>
      resourceType={ResourceType.Outcomes}
      blankslate={BLANK_SLATE_PROPS}
      loading={loading || cohortsLoading}
      data={data?.outcomes ?? []}
      columns={tableColumns}
      defaultSort={[
        {
          id: "createdAt",
          desc: true,
        },
      ]}
    />
  );
}
