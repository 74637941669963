import { TraitPermission } from "@fdy/faraday-js";
import { Icon } from "@phosphor-icons/react";

import { IconWithText } from "../ui/IconWithText";
import { ResourceIcon } from "../ui/ResourceIcon";
import { TagList } from "../ui/TagList";

export const traitPermissionInfo: Record<
  TraitPermission,
  { label: string; icon: Icon }
> = {
  add_to_scope_payload: {
    label: "Add to pipeline payload",
    icon: ResourceIcon.scopes,
  },
  define_cohort: {
    label: "Define cohort",
    icon: ResourceIcon.cohorts,
  },
  target_analysis: {
    label: "Target analysis",
    icon: ResourceIcon.targets,
  },
};

export function TraitPermissionsPills({
  permissions,
}: {
  permissions: TraitPermission[];
}) {
  return (
    <TagList
      tags={permissions.map((p) => {
        const { label, icon: Icon } = traitPermissionInfo[p];

        return (
          <IconWithText key={p}>
            <Icon key={p} />
            {label}
          </IconWithText>
        );
      })}
    />
  );
}
