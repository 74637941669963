import { Box, Radio, RadioGroup, useRadioGroupContext } from "@chakra-ui/react";
import React from "react";

/**
 * RadioItem intended to be used inside RadioGroupV3.Root
 *
 * @example
 * <RadioGroupV3.Root value={value} onChange={onChange}>
 *   <RadioGroupV3.Item value="1" label="Option 1">
 *     <p>Option 1 content</p>
 *   </RadioGroupV3.Item>
 * </RadioGroupV3.Root>
 */
const RadioItem = ({
  value,
  label,
  children,
}: {
  value: string;
  label: string;
  children?: React.ReactNode;
}) => {
  const ctx = useRadioGroupContext();

  const isSelected = ctx.value === value;

  return (
    <div>
      <div>
        <Radio
          value={value}
          isChecked={isSelected}
          onChange={() => ctx.onChange(value)}
          pt={2}
        >
          <Box as="span" fontWeight="bold">
            {label}
          </Box>
        </Radio>
      </div>
      {/* Display children if the radio item is selected */}
      {isSelected && children && (
        <Box pl={6} pt={4} pb={2}>
          {children}
        </Box>
      )}
    </div>
  );
};

export const RadioGroupV3 = {
  Root: RadioGroup,
  Item: RadioItem,
};
