import { VStack } from "@chakra-ui/react";
import { RecommenderMergePatch } from "@fdy/faraday-js";
import { sortBy } from "lodash";
import * as React from "react";
import { FormEvent, useState } from "react";

import { useStreamListSuspenseQuery } from "../../hooks/api";
import { useUnsavedChangesWarning } from "../../hooks/useUnsavedChangesWarning";
import { AnalyticsStack } from "../ui/Analytics/AnalyticsStack";
import { Button } from "../ui/Button";
import { CardV2 } from "../ui/Card/CardV2";
import { Cardv2Section } from "../ui/Card/Cardv2Section";
import { CardStack } from "../ui/CardStack";
import { Collapsible } from "../ui/Collapsible";
import { FormField } from "../ui/FormField";
import { Input } from "../ui/Input";
import { Select } from "../ui/Select";
import { SwitchV2 } from "../ui/Switch/SwitchV2";

type RecommenderFormState = RecommenderMergePatch;
export type RecommenderFormStateToWire = Required<RecommenderFormState>;

interface RecommendersFormProps {
  initialState?: RecommenderFormState;
  saving?: boolean;
  onSave: (state: RecommenderFormStateToWire) => void;
}

function RecommendersFormNameCard({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: string) => void;
}) {
  return (
    <CardV2>
      <FormField
        label="Recommender name"
        helpText={
          <>
            A strong recommender name is descriptive of its intent, and might be
            a summary of the population and payload, such as "Customer
            engagement with next best product."
          </>
        }
      >
        <Input
          value={value}
          onChange={(e) => onChange(e.target.value)}
          isRequired
          analyticsName="name"
        />
      </FormField>
    </CardV2>
  );
}

function RecommenderStreamAndPropertySelect({
  stream,
  property,
  onStreamChange,
  onPropertyChange,
}: {
  stream: string | undefined;
  property: string | undefined;
  onStreamChange: (stream: string) => void;
  onPropertyChange: (property: string) => void;
}) {
  const streamsQuery = useStreamListSuspenseQuery();

  const streamsWithProperties = streamsQuery.data?.filter(
    (s) =>
      Object.keys(s.properties ?? {}).length > 0 &&
      (!s.archived_at || s.name === stream)
  );
  const streams = sortBy(streamsWithProperties, (d) => d.name);
  const properties = Object.keys(
    streams.find((s) => s.name === stream)?.properties ?? {}
  );

  return (
    <AnalyticsStack value="config">
      <VStack spacing={3}>
        <FormField label="Event">
          <Select
            value={stream ?? ""}
            onChange={(e) => onStreamChange(e.target.value)}
            placeholder="Select event..."
            required
            analyticsName="event"
          >
            {streams.map((stream) => (
              <option key={stream.name} value={stream.name}>
                {stream.name}
              </option>
            ))}
          </Select>
        </FormField>
        <FormField label="Property">
          <Select
            value={property ?? ""}
            onChange={(e) => onPropertyChange(e.target.value)}
            placeholder="Select property..."
            disabled={!stream}
            required
            analyticsName="event-property"
          >
            {properties.map((property) => (
              <option key={property} value={property}>
                {property}
              </option>
            ))}
          </Select>
        </FormField>
      </VStack>
    </AnalyticsStack>
  );
}

function RecommendersFormDefinitionCard({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <CardV2
      title="What recommendation do you want?"
      text="Recommenders predict which product, service, or other approach is best for each individual."
    >
      <Cardv2Section
        title="Event"
        text="What event property do you want to predict? For example, to predict what product each customer is likely to buy, choose your transaction event stream and its product property."
        fieldset
      >
        {children}
      </Cardv2Section>
    </CardV2>
  );
}

export function RecommendersForm({
  initialState,
  saving,
  onSave,
}: RecommendersFormProps) {
  const [state, setState] = useState<RecommenderFormState>(initialState ?? {});

  //user warning message for unsaved changes
  const { setWarnBeforeNavigate } = useUnsavedChangesWarning({
    state,
  });

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const { name, stream_name, stream_property_name, preview } = state;

    // Mostly TS hand holding. The form below validates required fields already,
    // so users should never encounter these errors.
    if (!name) throw new Error("Name is required");
    if (!stream_name) throw new Error("Stream name is required");
    if (!stream_property_name)
      throw new Error("Stream property name is required");

    onSave({
      name,
      stream_name,
      stream_property_name,
      preview: preview ?? false,
    });
    setWarnBeforeNavigate(false);
  }

  return (
    <form onSubmit={handleSubmit}>
      <CardStack>
        <RecommendersFormDefinitionCard>
          <RecommenderStreamAndPropertySelect
            stream={state.stream_name}
            property={state.stream_property_name}
            onPropertyChange={(stream_property_name) =>
              setState({ ...state, stream_property_name })
            }
            onStreamChange={(stream_name) =>
              setState({ ...state, stream_name })
            }
          />
        </RecommendersFormDefinitionCard>

        <RecommendersFormNameCard
          value={state.name ?? ""}
          onChange={(name) => setState({ ...state, name })}
        />

        <CardV2>
          <Collapsible title="Advanced">
            <FormField
              label="Preview"
              helpText="A recommender in preview mode prevents models from building. Use this if you want to create a draft of configuration."
            >
              <SwitchV2
                label="Enable preview mode"
                isChecked={state.preview ?? false}
                onChange={(e) =>
                  setState((prev) => ({ ...prev, preview: e.target.checked }))
                }
              />
            </FormField>
          </Collapsible>
        </CardV2>

        <Button
          size="lg"
          type="submit"
          isLoading={saving}
          isDisabled={saving}
          loadingText="Saving recommender..."
          analyticsName="save"
        >
          Save recommender
        </Button>
      </CardStack>
    </form>
  );
}
