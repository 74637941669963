import { Stack } from "@chakra-ui/react";
import { ReactNode } from "react";

/**
 * Wrapper for separating vertical stack of form fields with a gap.
 * To be used with FormField
 */
export function FormFieldStack({ children }: { children: ReactNode }) {
  return <Stack spacing={6}>{children}</Stack>;
}
