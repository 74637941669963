export const PROTECTED_TRAITS = [
  "fig/age",
  "fig/child_1_birthdate",
  "fig/child_1_gender",
  "fig/child_2_birthdate",
  "fig/child_2_gender",
  "fig/child_3_birthdate",
  "fig/child_3_gender",
  "fig/child_4_birthdate",
  "fig/child_4_gender",
  "fig/children_in_household",
  "fig/date_of_birth",
  "fig/gender",
  "fig/household_type",
  "fig/marital_status",
  "fig/number_of_children",
  "fig/trigger_date_empty_nester",
  "fig/trigger_date_graduate",
  "fig/trigger_date_new_adult_to_file",
  "fig/trigger_date_newly_married",
  "fig/trigger_date_newly_single",
  "fig/trigger_date_new_young_adult_to_file",
  "fig/trigger_date_retired",
  "fig/trigger_date_value_score",
  "fig/trigger_empty_nester",
  "fig/trigger_events_graduate",
  "fig/trigger_new_adult_to_file",
  "fig/trigger_new_first_child",
  "fig/trigger_newly_married",
  "fig/trigger_newly_single",
  "fig/trigger_new_young_adult_to_file",
  "fig/trigger_retired",
  "fig/trigger_value_score",
  "fig/life_donor_other_religious_all",
  "fig/ds_lifecycle_1",
  "fig/ds_lifecycle_2",
  "fig/ds_lifecycle_3",
  "fig/ds_lifecycle_4",
  "fig/ds_lifecycle_5",
  "fig/ds_lifecycle_6",
  "fig/ds_lifecycle_7",
  "fig/ds_lifecycle_8",
  "fig/ds_lifecycle_8",
  "fig/ds_lifecycle_10",
  "fig/ds_lifecycle_11",
];
