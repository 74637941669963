import { Button, Text, useDisclosure } from "@chakra-ui/react";
import { Plus, XCircle } from "@phosphor-icons/react";

import { colors } from "../../../../styles/chakra-theme-v2";
import { CardV2 } from "../../../ui/Card/CardV2";
import { IconWithText } from "../../../ui/IconWithText";
import { UsageWarningNotice } from "../../../ui/UsageWarningNotice";
import { PipelineFragment as Scope } from "../../__generated__/PipelineFragment";
import { MarketAnalysesTable } from "./MarketAnalysesTable";
import { MarketAnalysisNewModal } from "./MarketAnalysisNewModal";

interface PipelineMarketOpportunityCardProps {
  scope: Scope;
}

export function PipelineMarketOpportunityCard({
  scope,
}: PipelineMarketOpportunityCardProps) {
  const newAnalysisModalprops = useDisclosure();
  const canAddAnalysis = scope.payload.outcomeIds.length > 0;

  const newAnalysisButton = (
    <Button
      size="sm"
      leftIcon={<Plus size={16} weight="bold" />}
      variant="secondary"
      onClick={newAnalysisModalprops.onOpen}
    >
      New analysis
    </Button>
  );

  return (
    <CardV2
      title="Market opportunity"
      text="If this pipeline represents your market, you can get powerful opportunity reporting here."
      cta={canAddAnalysis ? newAnalysisButton : null}
    >
      <UsageWarningNotice resource="market_opportunity_analyses" />
      {canAddAnalysis ? (
        <MarketAnalysesTable scope={scope} />
      ) : (
        <IconWithText>
          <XCircle weight="fill" color={colors.fdy_gray[400]} size={24} />
          <Text>
            Pipeline payload must contain at least one outcome to create a
            market opportunity analysis.
          </Text>
        </IconWithText>
      )}
      {newAnalysisModalprops.isOpen && (
        <MarketAnalysisNewModal
          scope={scope}
          onClose={newAnalysisModalprops.onClose}
        />
      )}
    </CardV2>
  );
}
