import { Notice } from "./Notice";

export function ResourcePreviewModeAlert({
  resource,
}: {
  resource: { preview?: boolean | null };
}) {
  if (!resource.preview) return null;

  return (
    <Notice
      variant="warning"
      title="This resource is in preview mode."
      description="It will not build outputs until enabled."
    />
  );
}
