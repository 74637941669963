import { capitalize } from "lodash";
import { Dispatch, SetStateAction, useCallback, useMemo } from "react";

import {
  GeographiesListItem,
  TraitCategory,
  TraitPermission,
} from "../../../../../__generated__/sojournerGlobalTypes";
import { Trait } from "../../../../../hooks/useTraitsQuery";
import { FdyReactSelect } from "../../../../ui/FdyReactSelect";
import { FormField } from "../../../../ui/FormField";
import { FormFieldStack } from "../../../../ui/FormFieldStack";
import { createGroupedTraitOptions } from "../../../../ui/TraitMultiSelect";
import { useId } from "../../../../ui/useId";
import {
  TraitGroup,
  TraitOption,
} from "../../../PipelineForm/pipelineFormUtils";

export type TargetAnalysisState = {
  traits: string[];
  geographies: GeographiesListItem[];
};

export const ANALYSIS_INITIAL_STATE: TargetAnalysisState = {
  traits: [],
  geographies: [],
};

export interface TargetAnalysisProps {
  onChange: Dispatch<SetStateAction<TargetAnalysisState>>;
  analysisConfig: TargetAnalysisState;
  traits: Trait[];
}

/**
 * Renders the inputs for modifying a target analysis
 */
export function TargetAnalysis({
  analysisConfig,
  onChange,
  traits,
}: TargetAnalysisProps) {
  const geographiesId = useId();
  const traitsId = useId();

  let hasGeographies = false;
  const filteredTraits: Trait[] = [];

  for (const trait of traits) {
    // probably could be one if/else but wanted to be very explicit that users should only see geographies select if they have trait_grant for them
    if (
      trait.category &&
      trait.category === TraitCategory.FIG_GEOGRAPHY &&
      trait.permissions.includes(TraitPermission.TARGET_ANALYSIS)
    ) {
      hasGeographies = true;
    }
    if (trait.category && trait.category !== TraitCategory.FIG_GEOGRAPHY) {
      filteredTraits.push(trait);
    }
  }

  const geographies: TraitOption[] = Object.values(GeographiesListItem).map(
    (item) => {
      return {
        label: capitalize(item.replace(/_/g, " ")),
        value: item,
      };
    }
  );

  // group traits by category
  const traitGroups = useMemo(
    () =>
      createGroupedTraitOptions(
        filteredTraits,
        analysisConfig.traits,
        TraitPermission.TARGET_ANALYSIS
      ),
    [filteredTraits]
  );

  // get selected traits to show in the select
  const traitValue = useMemo(() => {
    const allGroupOptions = traitGroups.flatMap((g) => g.options);

    return allGroupOptions.filter((t) =>
      analysisConfig.traits.includes(t.value)
    );
  }, [analysisConfig.traits, filteredTraits]);

  // get selected geographies to show in the select
  const geoValue = useMemo(() => {
    return geographies.filter((g) =>
      analysisConfig.geographies.includes(g.value as GeographiesListItem)
    );
  }, [analysisConfig.geographies, geographies]);

  const handleTraitChange = useCallback(
    (selectedOptions: readonly TraitOption[]) => {
      onChange((state) => ({
        ...state,
        traits: selectedOptions.map((opt) => opt.value),
      }));
    },
    [onChange]
  );

  const handleGeoChange = useCallback(
    (selectedOptions: readonly TraitOption[]) => {
      onChange((state) => ({
        ...state,
        geographies: selectedOptions.map(
          (opt) => opt.value as GeographiesListItem
        ),
      }));
    },
    [onChange]
  );

  return (
    <FormFieldStack>
      <FormField
        htmlFor={traitsId}
        label="Traits"
        analyticsName="traits-target-analysis"
        suffix="optional"
      >
        <FdyReactSelect<TraitOption, true>
          inputId={traitsId}
          isMulti
          options={traitGroups}
          value={traitValue}
          onChange={handleTraitChange}
          placeholder="Select traits..."
          limitShownMultiValue={6}
          selectableGroups
        />
      </FormField>
      {hasGeographies && (
        <FormField
          htmlFor={geographiesId}
          label="Geography"
          analyticsName="geography-target-analysis"
          suffix="optional"
        >
          <FdyReactSelect<TraitOption, true, TraitGroup>
            inputId={geographiesId}
            aria-label="geography-target-analysis"
            isMulti
            options={geographies}
            value={geoValue}
            onChange={handleGeoChange}
            limitShownMultiValue={6}
            selectableGroups
            placeholder="Select geographies..."
          />
        </FormField>
      )}
    </FormFieldStack>
  );
}
