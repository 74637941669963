import { forwardRef } from "@chakra-ui/react";
import * as React from "react";

import { useCohortListSuspenseQuery } from "../../hooks/api";
import { Select, SelectProps } from "./Select";

export const CohortSelect = forwardRef((props: SelectProps, ref) => {
  const { data } = useCohortListSuspenseQuery({
    select: (data) =>
      data
        .filter(
          (cohort) =>
            !cohort.archived_at ||
            // keep if it's already stored in config, regardless of archived status
            cohort.id === props.value
        )
        .sort((a, b) => a.name.localeCompare(b.name)),
  });

  return (
    <Select
      placeholder={props.placeholder || "Select a cohort..."}
      {...props}
      ref={ref}
    >
      {data.map((cohort) => (
        <option key={cohort.id} value={cohort.id}>
          {cohort.name}
        </option>
      ))}
    </Select>
  );
});
