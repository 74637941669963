import { Link } from "@chakra-ui/react";
import { Resource } from "@fdy/faraday-js";
import { ArrowSquareOut } from "@phosphor-icons/react";

import { singularize } from "../../../utils/english";
import { HttpRequest } from "./requestToHttpSnippet";

const methodToOperationIdMap: Record<HttpRequest["method"], string> = {
  GET: "get",
  POST: "create",
  PATCH: "update",
  DELETE: "delete",
};

export function RequestDocsLink({
  request,
  resource,
}: {
  request: HttpRequest;
  resource: Resource;
}) {
  const resourceType = singularize(resource.resource_type)
    // e.g. persona_set -> personaset
    .replace(/_/g, "")
    .toLowerCase();

  const methodName = methodToOperationIdMap[request.method];
  const operationId = methodName + resourceType;

  const docLink = `https://faraday.ai/developers/reference/${operationId}`;

  // Use callout link? need to remove its borders etc. Maybe make that inner
  // component into a smaller component that is just bold and has icon.
  return (
    <Link
      href={docLink}
      target="_blank"
      sx={{
        display: "flex",
        gap: 2,
        alignItems: "center",
      }}
    >
      <span>
        {methodName} {resourceType} docs
      </span>
      <ArrowSquareOut />
    </Link>
  );
}
