import { gql, WatchQueryFetchPolicy } from "@apollo/client";

import { ResourceType } from "../../__generated__/sojournerGlobalTypes";
import { useSojournerQuery } from "../../services/sojournerApolloClient";
import {
  DependencyGraphQuery,
  DependencyGraphQuery_graph,
} from "./__generated__/DependencyGraphQuery";

export const dependencyGraphQuery = gql`
  query DependencyGraphQuery {
    graph {
      upstreamId
      upstreamType
      upstreamLiterate
      upstreamStatus
      upstreamArchivedAt
      downstreamId
      downstreamType
      downstreamLiterate
      downstreamArchivedAt
    }
  }
`;

/**
 * Query the details of a pipeline aka scope.
 *
 * Useful for detail/show page and edit pages.
 */
export function useResourceGraphQuery(config?: {
  fetchPolicy?: WatchQueryFetchPolicy;
}) {
  return useSojournerQuery<DependencyGraphQuery>(dependencyGraphQuery, config);
}

export const RESOURCE_ORDER: Record<ResourceType, number> = {
  ACCOUNTS: -1, // shouldn't ever appear, but needed for typing
  CONNECTIONS: 0,
  DATASETS: 1,
  STREAMS: 2,
  TRAITS: 3,
  PLACES: 4,
  COHORTS: 5,
  OUTCOMES: 6,
  PERSONA_SETS: 7,
  RECOMMENDERS: 8,
  SCOPES: 9,
  TARGETS: 10,
  MARKET_OPPORTUNITY_ANALYSES: 11,
};

export function sortByResourceType(
  direction: GraphDirection = GraphDirection.DOWNSTREAM
) {
  if (direction === GraphDirection.UPSTREAM) {
    return (a: DependencyGraphQuery_graph, b: DependencyGraphQuery_graph) => {
      if (!a.upstreamType || !b.upstreamType) {
        if (!a.upstreamLiterate || !b.upstreamLiterate) return 0;
        return a.upstreamLiterate?.localeCompare(b.upstreamLiterate || "");
      }
      return RESOURCE_ORDER[a.upstreamType] - RESOURCE_ORDER[b.upstreamType];
    };
  } else if (direction === GraphDirection.DOWNSTREAM) {
    return (a: DependencyGraphQuery_graph, b: DependencyGraphQuery_graph) => {
      if (!a.downstreamType || !b.downstreamType) {
        if (!a.downstreamLiterate || !b.upstreamLiterate) return 0;
        return a.downstreamLiterate?.localeCompare(b.downstreamLiterate || "");
      }
      return (
        RESOURCE_ORDER[a.downstreamType] - RESOURCE_ORDER[b.downstreamType]
      );
    };
  } else throw new Error(`unsupported sort direction ${direction}`);
}

export enum GraphDirection {
  UPSTREAM,
  DOWNSTREAM,
}

export function getGraphForResource({
  edges,
  resourceId,
  direction = GraphDirection.DOWNSTREAM,
}: {
  edges: DependencyGraphQuery_graph[] | undefined;
  resourceId: string;
  direction: GraphDirection;
}): DependencyGraphQuery_graph[] {
  if (!edges) return [];

  const alreadyInResult: Record<string, DependencyGraphQuery_graph> = {};
  const edgeMapUpstream: Record<string, DependencyGraphQuery_graph[]> = {};
  const edgeMapDownstream: Record<string, DependencyGraphQuery_graph[]> = {};

  edges.forEach((edge) => {
    if (edge.upstreamId) {
      if (!edgeMapDownstream[edge.upstreamId]) {
        edgeMapDownstream[edge.upstreamId] = [];
      }
      edgeMapDownstream[edge.upstreamId].push(edge);
    }
    if (edge.downstreamId) {
      if (!edgeMapUpstream[edge.downstreamId]) {
        edgeMapUpstream[edge.downstreamId] = [];
      }
      edgeMapUpstream[edge.downstreamId].push(edge);
    }
  });

  // Recursive function to traverse downstream edges
  // ensure no duplicate resources
  // (eg if an event stream has two source datasets, which use the same connection,
  //  only list the connection once)
  function traverseDown(resourceId: string): void {
    if (edgeMapDownstream[resourceId]) {
      edgeMapDownstream[resourceId].forEach((edge) => {
        // const currentId = edge.upstreamId;
        const nextId = edge.downstreamId;
        if (nextId && !alreadyInResult[nextId]) {
          alreadyInResult[nextId] = edge;
          if (nextId) traverseDown(nextId);
        }
      });
    }
  }

  function traverseUp(resourceId: string): void {
    if (edgeMapUpstream[resourceId]) {
      edgeMapUpstream[resourceId].forEach((edge) => {
        // const currentId = edge.downstreamId;
        const nextId = edge.upstreamId;
        if (nextId && !alreadyInResult[nextId]) {
          alreadyInResult[nextId] = edge;
          if (nextId) traverseUp(nextId);
        }
      });
    }
  }

  // Start traversal from the given resource
  if (direction === GraphDirection.UPSTREAM) traverseUp(resourceId);
  else if (direction === GraphDirection.DOWNSTREAM) traverseDown(resourceId);

  return Object.values(alreadyInResult);
}
