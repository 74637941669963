import { useCallback, useMemo } from "react";

import { TraitPermission } from "../../../__generated__/sojournerGlobalTypes";
import { useTraitsQuery } from "../../../hooks/useTraitsQuery";
import { FdyReactSelect } from "../../ui/FdyReactSelect";
import { FormField } from "../../ui/FormField";
import { createGroupedTraitOptions } from "../../ui/TraitMultiSelect";
import { useId } from "../../ui/useId";
import { PipelineFormState } from "./PipelineForm";
import { SetPiplineFormState } from "./PipelineFormPayloadCard";
import { TraitGroup, TraitOption } from "./pipelineFormUtils";
import { pipelineHelpText } from "./pipelineHelpText";
import { ResetScopeError, ScopeErrors } from "./usePipelineValidator";

export function PipelineFormPayloadTraits({
  formState,
  setFormState,
  errors,
  resetError,
}: {
  formState: PipelineFormState;
  setFormState: SetPiplineFormState;
  errors: ScopeErrors | undefined;
  resetError: ResetScopeError;
}) {
  const id = useId();

  // fetch traits
  const { loadingTraits, traits } = useTraitsQuery();

  // group traits by category
  const traitGroups = useMemo(
    () =>
      createGroupedTraitOptions(
        traits,
        formState.attributes,
        TraitPermission.ADD_TO_SCOPE_PAYLOAD
      ),
    [traits]
  );

  // get selected traits to show in the select
  const value = useMemo(() => {
    const allGroupOptions = traitGroups.flatMap((g) => g.options);

    return allGroupOptions.filter((t) =>
      formState.attributes.includes(t.value)
    );
  }, [formState.attributes, traits]);

  // handle change of selected traits
  const handleChange = useCallback(
    (selectedOptions: readonly TraitOption[]) => {
      setFormState((state) => ({
        ...state,
        attributes: selectedOptions.map((opt) => opt.value),
      }));

      resetError("predictions");
      resetError("cohortMembership");
      resetError("traits");
    },
    [setFormState, resetError]
  );

  return (
    <FormField
      label="Traits"
      htmlFor={id}
      helpText={pipelineHelpText.traits}
      error={errors?.traits}
      analyticsName="traits"
    >
      <FdyReactSelect<TraitOption, true, TraitGroup>
        inputId={id}
        isMulti
        isLoading={loadingTraits}
        isDisabled={loadingTraits}
        options={traitGroups}
        value={value}
        onChange={handleChange}
        placeholder="Select traits..."
        isInvalid={Boolean(errors?.traits)}
        limitShownMultiValue={6}
        selectableGroups
      />
    </FormField>
  );
}
