import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { MarketOpportunityAnalysis } from "@fdy/faraday-js";
import { DotsThreeOutline } from "@phosphor-icons/react";

import { ResourceDeleteButton } from "../../../ui/ResourceDeleteButton";
import { PipelineFragment } from "../../__generated__/PipelineFragment";
import { MarketAnalysisEditModal } from "./MarketAnalysisEditModal";

export function MarketAnalysisActionMenu({
  analysis,
  scope,
}: {
  analysis: MarketOpportunityAnalysis;
  scope: PipelineFragment;
}) {
  const editModalProps = useDisclosure();

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label={`Toggle ${analysis.name} actions`}
          size="sm"
          variant="icon"
          color="fdy_gray.600"
          icon={<DotsThreeOutline weight="fill" />}
        >
          Actions
        </MenuButton>
        <MenuList>
          <MenuItem onClick={editModalProps.onOpen}>Edit</MenuItem>

          {analysis.google_sheets_url && (
            <MenuItem as="a" href={analysis.google_sheets_url}>
              Open in Google Sheets
            </MenuItem>
          )}

          {analysis.google_slides_url && (
            <MenuItem as="a" href={analysis.google_slides_url}>
              Open in Google Slides
            </MenuItem>
          )}

          <ResourceDeleteButton
            resourceType="market_opportunity_analyses"
            resourceId={analysis.id}
            name={analysis.name}
            resourceStatus={analysis.status}
            isMenuItem
          />
        </MenuList>
      </Menu>

      {editModalProps.isOpen && (
        <MarketAnalysisEditModal
          scope={scope}
          analysis={analysis}
          onClose={editModalProps.onClose}
        />
      )}
    </>
  );
}
