import {
  format,
  formatDistanceToNow,
  isBefore,
  parseISO,
  subDays,
} from "date-fns";
import numeral from "numeral";

export function plural(num: number, singular: string, plural: string) {
  return num === 1 ? singular : plural;
}

export function number(v: number) {
  return numeral(v).format("0,0");
}

number.decimal = function (v: number) {
  return numeral(v).format("0.[0]");
};

export function largeNumber(v: number) {
  return numeral(v).format("0a");
}

export function kilobytes(v: number) {
  return numeral(v * 1024).format("0b");
}

export function bytes(v: number) {
  return numeral(v).format("0b");
}

export function percent(v: number) {
  return numeral(isNaN(v) ? 0 : v).format("0%");
}

export function dollars(v: number) {
  return numeral(v).format("$0,0");
}

export function dollarsAbrev(v: number) {
  let format = "$0";
  if (v >= 1000) {
    format = "$0,0";
  }
  if (v >= 1000000) {
    format = "$0[.]00a";
  }
  return numeral(v).format(format);
}

export function dollarsCents(v: number) {
  return numeral(v).format("$0,0[.]00");
}

export function date(d: string | Date, fmt = "MMMM d, yyyy") {
  // There is a bug in date-fns where date strings close to daylight savings time
  // transitions are off by a day. See: https://github.com/date-fns/date-fns/issues/1816
  const isoDate = typeof d === "string" ? parseISO(d) : d;
  return format(isoDate, fmt);
}

export function yearMonthDaySlug(d: string | Date) {
  return format(d, "yyyy-MM-dd");
}

date.relative = function (d: string | Date) {
  return formatDistanceToNow(d);
};

date.recentRelative = function (d: string) {
  function isMoreThan14DaysAgo(d: string) {
    return isBefore(d, subDays(new Date(), 14));
  }

  if (isMoreThan14DaysAgo(d)) {
    return yearMonthDaySlug(d);
  } else {
    return date.relative(d) + " ago";
  }
};

date.dateAndTime = function (d: string | Date) {
  return date(d, "MMM d, yyyy h:mma");
};

date.parseISOString = function (date: string) {
  // parse ISO date or date-time string, return an array of [year, month, day]
  // intended use: `new Date(...parseISOString('YYYY-MM-DD'))`
  // https://github.com/date-fns/date-fns/issues/489
  const [year, month, day] = date.slice(0, 10).split("-");
  // (month - 1) because JS months index from zero
  return [Number(year), Number(month) - 1, Number(day)];
};

// convert ISO date string (2022-01-01) to a Date object
export function isoDate(str: string) {
  const [year, month, day] = date.parseISOString(str);
  return new Date(year, month, day);
}

export function titleCase(str: string) {
  return str[0].toUpperCase() + str.toLowerCase().slice(1);
}
