import { useFormControlContext } from "@chakra-ui/react";
import * as React from "react";
import { useCallback, useMemo } from "react";
import { MultiValue } from "react-select";

import { TraitPermission } from "../../__generated__/sojournerGlobalTypes";
import { Trait, useTraitsQuery } from "../../hooks/useTraitsQuery";
import { groupAndSortTraitsByCategories } from "../traits/traitUtils";
import {
  FdyReactSelect,
  FdySelectGroupBase,
  FdySelectOption,
} from "./FdyReactSelect";

/**
 * Creates grouped trait options for a multi-select component.
 *
 * @param traits - An array of Trait objects to be grouped and filtered.
 * @param state - An array of strings representing the current state of selected traits.
 * @param permission - An optional TraitPermission to filter traits by their permissions.
 * @returns An array of FdySelectGroupBase objects, each containing a label and an array of FdySelectOption objects.
 */
export function createGroupedTraitOptions(
  traits: Trait[],
  state: string[],
  permission?: TraitPermission
): FdySelectGroupBase<FdySelectOption>[] {
  return groupAndSortTraitsByCategories(
    traits.filter((trait) => {
      // in use already
      if (state.find((name) => name === trait.name)) return true;

      const permitted = permission
        ? trait.permissions.includes(permission)
        : true;

      const enabled = !trait.deprecated;

      return permitted && enabled;
    })
  ).map((group) => {
    const options: FdySelectOption[] = group.traits.map((trait) => {
      return {
        label: trait.literate,
        value: trait.name,
      };
    });

    return {
      label: group.categoryLiterate,
      options,
    };
  });
}

/**
 * A multi-select component for selecting traits.
 * Sorts traits into groups by category.
 * Users can select multiple traits at once by clicking the group name.
 */
export function TraitMultiSelect({
  value,
  onChange,
  isInvalid,
}: {
  value: string[];
  onChange: (values: string[]) => void;
  isInvalid?: boolean;
}) {
  const controlContext = useFormControlContext();
  const { traits, loadingTraits } = useTraitsQuery();

  const traitGroups = useMemo(
    () => createGroupedTraitOptions(traits, value),
    [traits]
  );

  const selectedOptions = useMemo(() => {
    const allGroupOptions = traitGroups.flatMap((g) => g.options);
    return allGroupOptions.filter((t) => value.includes(t.value));
  }, [value, traits]);

  const handleChange = useCallback(
    (newValue: MultiValue<FdySelectOption>) => {
      onChange(newValue.map((v) => v.value));
    },
    [onChange]
  );

  return (
    <FdyReactSelect<FdySelectOption, true, FdySelectGroupBase<FdySelectOption>>
      inputId={controlContext?.id}
      isMulti
      options={traitGroups}
      value={selectedOptions}
      isLoading={loadingTraits}
      isDisabled={loadingTraits}
      onChange={handleChange}
      placeholder="Select traits..."
      isInvalid={isInvalid}
      limitShownMultiValue={6}
      selectableGroups
    />
  );
}
