import {
  MutationCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import * as React from "react";

import { FdyClientError } from "../../services/FdyClientProvider";

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        // don't retry 404
        if (error instanceof FdyClientError && error.statusCode === 404) {
          return false;
        }
        return failureCount <= 6;
      },

      throwOnError: true,
    },
  },
  mutationCache: new MutationCache({
    onSuccess: () => {
      // https://tkdodo.eu/blog/automatic-query-invalidation-after-mutations
      client.invalidateQueries();
    },
  }),
});

export function ReactQueryProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return <QueryClientProvider client={client}>{children}</QueryClientProvider>;
}
