import { ResourceType } from "@fdy/faraday-js";

/**
 * Front-end display names for resource types
 */
export const typeAlias: Partial<Record<ResourceType, string>> = {
  cohorts: "cohort",
  connections: "connection",
  datasets: "dataset",
  outcomes: "outcome",
  persona_sets: "persona set",
  scopes: "pipeline",
  streams: "stream",
  targets: "deployment",
  traits: "trait",
  recommenders: "recommender",
  market_opportunity_analyses: "market opportunity analysis",
};

/**
 * Convert a resource type to a human-readable form (aliased and capitalized)
 */
export const resourceTypeAlias = (type: ResourceType) =>
  typeAlias[type] || type;
