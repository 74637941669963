import { PrimitiveDataType } from "../../../../../__generated__/sojournerGlobalTypes";
import { Trait } from "../../../../../hooks/useTraitsQuery";
import {
  FilterableScopeDependencies,
  Operator,
  PayloadElemOption,
} from "./types";

const floatInputProps = {
  min: 0,
  max: 1,
  step: "any",
};

function personaOptions(
  deps: FilterableScopeDependencies
): PayloadElemOption[] {
  const options: PayloadElemOption[] = [];

  for (const personaSet of deps.payloadPersonaSets) {
    const personaValues = personaSet.personas.map((persona) => {
      if (!persona.name) {
        throw new Error(`Persona ${persona.id} has no name.`);
      }
      return {
        value: persona.id,
        label: persona.name,
      };
    });

    const building = personaSet.personas.length === 0;

    options.push({
      type: "persona",
      label: `${personaSet.name} ${building ? " (building...)" : ""}`,
      operators: ["eq", "neq"],
      value: personaSet.id,
      options: personaValues,
      defaultValue:
        personaValues.length > 0 ? personaValues[0].value : undefined,
      disabled: building,
    });
  }
  return options;
}

function cohortOptions(deps: FilterableScopeDependencies): PayloadElemOption[] {
  const options: PayloadElemOption[] = [];

  for (const cohort of deps.payloadCohorts) {
    options.push({
      type: "cohortMembership",
      label: cohort.name,
      operators: ["eq"],
      value: cohort.id,
      options: [
        {
          label: "False",
          value: "false",
        },
        {
          label: "True",
          value: "true",
        },
      ],
      defaultValue: "true",
    });
  }
  return options;
}

function getPayloadElemOptionFromTrait(trait: Trait): PayloadElemOption {
  const picklist = trait.categories?.map((category) => ({
    label: category,
    value: category,
  }));

  const stringOptions: PayloadElemOption = {
    type: "trait",
    label: trait.literate ?? trait.name,
    operators: ["eq", "neq", "matches", "in", "nin", "null", "nnull"],
    value: trait.name,
    picklist,
  };

  switch (trait.type) {
    case PrimitiveDataType.STRING: {
      return stringOptions;
    }

    case PrimitiveDataType.BOOLEAN:
      return {
        type: "trait",
        label: trait.literate ?? trait.name,
        operators: ["eq", "neq", "null", "nnull"],
        value: trait.name,
        options: [
          {
            label: "True",
            value: "true",
          },
          {
            label: "False",
            value: "false",
          },
        ],
        defaultValue: "true",
      };

    case PrimitiveDataType.DOUBLE:
    case PrimitiveDataType.LONG:
      return {
        type: "trait",
        label: trait.literate ?? trait.name,
        operators: ["eq", "gt", "lt", "gte", "lte"],
        value: trait.name,
      };

    default:
      return stringOptions; //default to string so we can handle Date types i.e date_of_birth
  }
}

function traitOptions(
  deps: FilterableScopeDependencies,
  traits?: Trait[]
): PayloadElemOption[] {
  const options: PayloadElemOption[] = [];

  if (traits === undefined || traits.length === 0 || deps === undefined)
    return options;

  for (const trait of deps.payload.attributes) {
    if (trait !== null) {
      const fullTrait = traits.find((t) => t.name === trait);

      if (fullTrait === undefined) {
        console.warn(`Trait ${trait} not found`);
        continue;
      }

      options.push(getPayloadElemOptionFromTrait(fullTrait));
    }
  }

  return options;
}

const numberOperators: Operator[] = [
  "eq",
  "gt",
  "lt",
  "gte",
  "lte",
  "null",
  "nnull",
];

function outcomeScoreOptions(
  deps: FilterableScopeDependencies
): PayloadElemOption[] {
  const options: PayloadElemOption[] = [];

  for (const outcome of deps.payloadOutcomes) {
    options.push({
      type: "outcomeScore",
      label: outcome.name,
      operators: numberOperators,
      value: outcome.id,
    });
  }
  return options;
}

function outcomeProbabilityOptions(
  deps: FilterableScopeDependencies
): PayloadElemOption[] {
  const options: PayloadElemOption[] = [];

  for (const outcome of deps.payloadOutcomes) {
    options.push({
      type: "outcomeProbability",
      label: outcome.name,
      operators: numberOperators,
      value: outcome.id,
      ...floatInputProps,
    });
  }
  return options;
}

function outcomePercentileOptions(
  deps: FilterableScopeDependencies
): PayloadElemOption[] {
  const options: PayloadElemOption[] = [];

  for (const outcome of deps.payloadOutcomes) {
    options.push({
      type: "outcomePercentile",
      label: outcome.name,
      operators: numberOperators,
      value: outcome.id,
    });
  }
  return options;
}

function recommenderRankOptions(
  deps: FilterableScopeDependencies
): PayloadElemOption[] {
  const options: PayloadElemOption[] = [];

  for (const recommender of deps.payloadRecommenders) {
    options.push({
      type: "recommenderRank",
      label: recommender.name,
      operators: numberOperators,
      value: recommender.id,
      min: 1,
    });
  }

  return options;
}

function recommenderUncalibratedProbabilityOptions(
  deps: FilterableScopeDependencies
): PayloadElemOption[] {
  const options: PayloadElemOption[] = [];

  for (const recommender of deps.payloadRecommenders) {
    options.push({
      type: "recommenderUncalibratedProbability",
      label: recommender.name,
      operators: numberOperators,
      value: recommender.id,
      ...floatInputProps,
    });
  }

  return options;
}

/**
 * Given a scope, turn its payload contents into a list of filter options.
 */
export function scopeToTargetFilterOptions(
  scopeDependencies: FilterableScopeDependencies,
  showOutcomeScore: boolean,
  traits?: Trait[]
): PayloadElemOption[] {
  // ensure no broken state for payload columns that use traits
  if (
    scopeDependencies.payload.attributes.length > 0 &&
    (traits === undefined || traits.length === 0)
  ) {
    throw new Error(
      "Must provide traits map if payload attribute/trait columns are present."
    );
  }

  return [
    ...personaOptions(scopeDependencies),
    ...cohortOptions(scopeDependencies),
    ...outcomePercentileOptions(scopeDependencies),
    ...(showOutcomeScore ? outcomeScoreOptions(scopeDependencies) : []),
    ...outcomeProbabilityOptions(scopeDependencies),
    ...recommenderRankOptions(scopeDependencies),
    ...recommenderUncalibratedProbabilityOptions(scopeDependencies),
    ...traitOptions(scopeDependencies, traits),
  ];
}
