import {
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
  forwardRef,
} from "@chakra-ui/react";

import { analyticsAttrs, useAnalyticsKey } from "./Analytics/AnalyticsStack";

export interface CheckboxProps extends ChakraCheckboxProps {
  analyticsName?: string;
}

export const Checkbox = forwardRef<CheckboxProps, "input">(
  ({ analyticsName, ...props }, ref) => {
    const { children, ...rest } = props;
    const analyticsKey = useAnalyticsKey(analyticsName);
    return (
      <ChakraCheckbox ref={ref} {...rest} {...analyticsAttrs(analyticsKey)}>
        {children}
      </ChakraCheckbox>
    );
  }
);
