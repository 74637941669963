import { gql } from "@apollo/client";

import { personaSetFragment } from "./personaSetFragment";

export const PERSONAS_LIST_QUERY = gql`
  query PersonasListQuery {
    personaSets {
      ...PersonaSetFragment
    }
  }
  ${personaSetFragment}
`;
