import { Button } from "@chakra-ui/react";
import { MarketOpportunityAnalysis } from "@fdy/faraday-js";
import { useId } from "react";

import { useMarketOpportunityAnalysisUpdateMutation } from "../../../../hooks/api";
import { useApiErrorToaster } from "../../../../hooks/useApiErrorToaster";
import { useToast } from "../../../../hooks/useToast";
import { InlineButtons } from "../../../ui/InlineButtons";
import { ModalV2 } from "../../../ui/ModalV2";
import { PipelineFragment } from "../../__generated__/PipelineFragment";
import {
  MarketAnalysisForm,
  MarketAnalysisFormState,
} from "./MarketAnalysisForm";

export function MarketAnalysisEditModal({
  analysis,
  onClose,
  scope,
}: {
  analysis: MarketOpportunityAnalysis;
  onClose: () => void;
  scope: PipelineFragment;
}) {
  const toast = useToast();
  const formId = useId();
  const updateAnalysisMutation = useMarketOpportunityAnalysisUpdateMutation();
  useApiErrorToaster(updateAnalysisMutation.error);

  const handleSave = async (state: MarketAnalysisFormState) => {
    await updateAnalysisMutation.mutateAsync({
      id: analysis.id,
      data: {
        name: state.name,
        scope_id: scope.id,
        outcome_id: state.outcome_id,
        persona_id: state.persona_id ?? undefined,
        persona_set_id: state.persona_set_id ?? undefined,
      },
    });

    toast({
      title: "Analysis updated",
      description:
        "Your analysis will start rebuilding soon and will refresh monthly on or around the 1st of each month.",
      status: "success",
    });

    onClose();
  };

  const initialState = {
    name: analysis.name,
    outcome_id: analysis.outcome_id,
    persona_set_id: analysis.persona_set_id ?? null,
    persona_id: analysis.persona_id ?? null,
  };

  return (
    <ModalV2
      title="Edit analysis"
      onClose={onClose}
      isOpen
      footer={
        <InlineButtons reverse>
          <Button
            type="submit"
            form={formId}
            variant="primary"
            loadingText="Saving..."
            isLoading={updateAnalysisMutation.isPending}
          >
            Save
          </Button>
          <Button type="button" onClick={onClose} variant="tertiary">
            Cancel
          </Button>
        </InlineButtons>
      }
    >
      <MarketAnalysisForm
        formId={formId}
        initialState={initialState}
        scope={scope}
        onSave={handleSave}
      />
    </ModalV2>
  );
}
