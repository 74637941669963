import { Box, Heading, SimpleGrid } from "@chakra-ui/react";
import { PencilSimple } from "@phosphor-icons/react";

import { PredictionMode } from "../../../__generated__/sojournerGlobalTypes";
import { ROUTE_NAMES } from "../../../constants/routeNames";
import { CardV2 } from "../../ui/Card/CardV2";
import { LinkButton } from "../../ui/LinkButton";
import { MutedText } from "../../ui/MutedText";
import { ResourceIcon } from "../../ui/ResourceIcon";
import { RouterLink } from "../../ui/RouterLink";
import { Truncate } from "../../ui/Truncate";
import { OutcomeFragment } from "../__generated__/OutcomeFragment";
import { OutcomeSummary } from "./OutcomeSummary";

function DefinitionCohort({
  title,
  cohortId,
  cohortName,
  emptyValue,
}: {
  title: string;
  cohortId: string | null;
  cohortName: string | null;
  emptyValue: string;
}) {
  return (
    <Box>
      <Heading as="h3" fontSize="fdy_md" fontWeight="bold" mb={2}>
        {title}
      </Heading>
      <Box>
        {cohortId && cohortName ? (
          <RouterLink
            routeName={ROUTE_NAMES.COHORTS_SHOW}
            params={{ cohort: cohortId }}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <span>
              <ResourceIcon.cohorts />
            </span>{" "}
            <Truncate title={cohortName}>{cohortName}</Truncate>
          </RouterLink>
        ) : (
          <MutedText>{emptyValue}</MutedText>
        )}
      </Box>
    </Box>
  );
}

export function OutcomeDefinitionCard({
  outcome,
}: {
  outcome: OutcomeFragment;
}) {
  return (
    <CardV2
      title="Outcome definition"
      text={
        <OutcomeSummary
          attainment={outcome.attainmentCohortName}
          eligibility={outcome.eligibleCohortName}
          attrition={outcome.attritionCohortName}
        />
      }
    >
      <SimpleGrid
        columns={outcome.predictionMode === PredictionMode.STATIC ? 4 : 3}
        gap={4}
        mb={6}
      >
        <DefinitionCohort
          title="Eligibility cohort"
          cohortId={outcome.eligibleCohortId}
          cohortName={outcome.eligibleCohortName}
          emptyValue="Anyone in Faraday's identity graph"
        />

        <DefinitionCohort
          title="Attainment cohort"
          cohortId={outcome.attainmentCohortId}
          cohortName={outcome.attainmentCohortName}
          emptyValue="Please contact support as this Outcome is misconfigured"
        />

        <DefinitionCohort
          title="Attrition cohort"
          cohortId={outcome.attritionCohortId}
          cohortName={outcome.attritionCohortName}
          emptyValue="None"
        />
        {outcome.predictionMode === PredictionMode.STATIC && (
          <DefinitionCohort
            title="Prediction mode"
            emptyValue="Static"
            cohortId={null}
            cohortName={null}
          />
        )}
      </SimpleGrid>
      <LinkButton
        width="full"
        variant="secondary"
        routeName={ROUTE_NAMES.OUTCOMES_EDIT}
        params={{ outcome: outcome.id }}
        leftIcon={<PencilSimple />}
        analyticsName="definition-edit-outcome"
      >
        Edit
      </LinkButton>
    </CardV2>
  );
}
