import { FdySelectGroupBase, FdySelectOption } from "../../ui/FdyReactSelect";

export interface TraitOption extends FdySelectOption {}
export interface TraitGroup extends FdySelectGroupBase<TraitOption> {}

export const pipelineFormIds = {
  predictions: "predictions",
  cohortMembership: "cohortMembership",
  traits: "traits",
  population: "population",
  populationExclusion: "populationExclusion",
  name: "name",
};

export const pipelineFormLabels = {
  predictions: "Predictions",
  cohortMembership: "Cohort membership",
  population: "Population to include",
  populationExclude: "Population to exclude",
  name: "Pipeline name",
  save: "Save pipeline",
  saving: "Saving pipeline...",
};

/**
 * A distinct id for 'everyone' since we present it in the population options.
 */
export const EVERYONE_ID = "EVERYONE_ID";

export function sortByLabel<T extends { label: string }>(a: T, b: T) {
  return a.label.localeCompare(b.label);
}
