import { ResourceType } from "@fdy/faraday-js";
import { ColumnDef } from "@tanstack/react-table";
import { useMemo } from "react";

import { ResourceStatus } from "../../../__generated__/sojournerGlobalTypes";
import { ROUTE_NAMES } from "../../../constants/routeNames";
import { useAccountConfigMap } from "../../../hooks/accountConfigHooks";
import { useSojournerQuery } from "../../../services/sojournerApolloClient";
import { showInExploreTooltip } from "../../cohorts/CohortsListPage/CohortsTable";
import { ResourceLink } from "../../ui/ResourceLink";
import { ResourceStatusBadge } from "../../ui/ResourceStatus/ResourceStatusBadge";
import { ResourceTable } from "../../ui/ResourceTable";
import { TimeAgo } from "../../ui/TimeAgo";
import {
  PersonasListQuery,
  PersonasListQuery_personaSets as PersonaSet,
} from "../__generated__/PersonasListQuery";
import { PersonaSetActionsMenu } from "../PersonaSetActionsMenu";
import { PersonaSetExploreSwitch } from "../PersonaSetExploreSwitch";
import { PERSONAS_LIST_QUERY } from "../personasQuery";

const BLANK_SLATE_PROPS = {
  title: "No Persona Sets found",
  text: "Persona Sets cluster US consumers using demographic, financial, and other features.",
  button: {
    routeName: ROUTE_NAMES.COHORTS,
    children:
      "Use Cohorts to create groups of US consumers to be used in a Persona Set.",
  },
};

export function PersonaSetsTable() {
  const config = useAccountConfigMap();

  const { data, loading, error } = useSojournerQuery<PersonasListQuery>(
    PERSONAS_LIST_QUERY,
    {
      pollInterval: 10000,
    }
  );
  if (error) throw error;

  const tableColumns: ColumnDef<PersonaSet>[] = useMemo(
    () => [
      {
        accessorFn: (row) => row.name,
        header: "Name",
        cell: ({ row }) => (
          <ResourceLink resource={row.original} sx={{ fontWeight: "bold" }} />
        ),
      },
      {
        accessorFn: (row) => row.personas,
        header: "Personas in set",
        size: 200,
        sortingFn: (a, b) =>
          a.original.personas.length - b.original.personas.length,
        cell: ({ row }) =>
          row.original.status === ResourceStatus.READY
            ? row.original.personas.length
            : null,
      },
      {
        accessorFn: (row) => row.cohort?.name,
        header: "Cohort",
        cell: ({ row }) =>
          row.original.cohort ? (
            <ResourceLink resource={row.original.cohort} />
          ) : null,
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: ({ row }) => (
          <ResourceStatusBadge
            resource={row.original}
            titlePrefix={row.original.preview ? "Preview" : ""}
          />
        ),
        sortingFn: "basic",
      },
      {
        accessorKey: "createdAt",
        header: "Created",
        cell: ({ row }) => <TimeAgo date={row.original.createdAt} />,
        sortingFn: "datetime",
      },
      {
        accessorKey: "lastUpdatedConfigAt",
        header: "Modified",
        cell: ({ row }) => <TimeAgo date={row.original.lastUpdatedConfigAt} />,
        sortingFn: "datetime",
      },
      {
        accessorKey: "lastUpdatedOutputAt",
        header: "Processed",
        cell: ({ row }) => <TimeAgo date={row.original.lastUpdatedOutputAt} />,
        sortingFn: "datetime",
      },
      {
        id: "explore",
        header: "Explore",
        enableSorting: false,
        meta: {
          headerTooltip: showInExploreTooltip,
        },
        cell: ({ row }) => (
          <PersonaSetExploreSwitch personaSet={row.original} />
        ),
      },
      {
        id: "actions",
        header: "Actions",
        size: 64,
        enableSorting: false,
        cell: ({ row }) => (
          <PersonaSetActionsMenu
            personaSet={row.original}
            options={{
              view: true,
              rename: true,
              api: true,
              delete: true,
              archive: true,
            }}
            buttonProps={{
              variant: "icon",
              my: -2,
            }}
          />
        ),
      },
    ],
    []
  );

  return (
    <ResourceTable<PersonaSet>
      resourceType={ResourceType.PersonaSets}
      blankslate={BLANK_SLATE_PROPS}
      loading={loading}
      data={data?.personaSets ?? []}
      columns={tableColumns}
      columnVisibility={{
        explore: config["explore.enabled"],
      }}
      defaultSort={[
        {
          id: "createdAt",
          desc: true,
        },
      ]}
    />
  );
}
