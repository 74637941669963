import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { ResourceType } from "@fdy/faraday-js";
import { DotsThreeOutline } from "@phosphor-icons/react";
import { useRef } from "react";

import { useAccountConfig } from "../../../../hooks/accountConfigHooks";
import { getApiBaseUrl } from "../../../../services/getApiBaseUrl";
import { ActionMenuChiefLink } from "../../../ui/ActionMenuChiefLink";
import { ApiShortcutsModal } from "../../../ui/ApiDocs/ApiShortcutsModal";
import { RenameModal } from "../../../ui/RenameModal";
import { ArchiveMenuButton } from "../../../ui/ResourceArchiveButton";
import { ResourceDeleteButton } from "../../../ui/ResourceDeleteButton";
import { TargetFragment as Target } from "../__generated__/TargetFragment";
import { useUpdateTarget } from "./UseUpdateTarget";

export type TargetActions = {
  rename?: boolean;
  edit?: boolean;
  api?: boolean;
  archive?: boolean;
  delete?: boolean;
};

function useApiDownloadUrl(endpoint: string): string | null {
  const config = useAccountConfig();
  if (config.loading) return null;
  return `${getApiBaseUrl()}${endpoint}?key=${config.api_key}`;
}

export function TargetAnalysisDownloadLink({
  endpoint,
  children,
}: {
  endpoint: string;
  children: React.ReactNode;
}) {
  const downloadUrl = useApiDownloadUrl(endpoint);
  if (!downloadUrl) return null;
  return (
    <MenuItem as="a" href={downloadUrl} download>
      {children}
    </MenuItem>
  );
}

export function TargetActionsMenu({
  actions,
  onEditClick,
  target,
}: {
  target: Target;
  onEditClick: () => void;
  actions: TargetActions;
}) {
  const renameModalState = useDisclosure();
  const apiShortcutsModalProps = useDisclosure();
  const menuBtnRef = useRef<HTMLButtonElement>(null);

  const { updateTarget, updating } = useUpdateTarget({
    onCompleted() {
      renameModalState.onClose();
    },
  });

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label={`Toggle ${target.name} actions`}
          icon={<DotsThreeOutline weight="fill" />}
          variant="ghost"
          size="sm"
          my={-2}
        />

        <MenuList>
          {actions.rename && (
            <MenuItem onClick={renameModalState.onOpen}>Rename</MenuItem>
          )}
          {actions.edit && <MenuItem onClick={onEditClick}>Edit</MenuItem>}
          {actions.api && (
            <MenuItem onClick={apiShortcutsModalProps.onOpen}>
              API Shortcuts
            </MenuItem>
          )}
          {/* This logic is determining 1)Has the target config changed 2) Is the config change before the last output of the target, if so then that means the targets current state reflects the config changes (such as adding analysis config) 3) Does analysis_config exist. If all three are true then we should show the download link because it means the analysis calc should have run and the pdf created. Its the best we can do right now since we don't store the signed url as a string in the db anymore, we just call an endpoint */}
          {target.lastUpdatedConfigAt &&
            target.lastUpdatedOutputAt &&
            new Date(target.lastUpdatedConfigAt) <
              new Date(target.lastUpdatedOutputAt) &&
            target.analysisConfig && (
              <TargetAnalysisDownloadLink
                endpoint={`/targets/${target.id}/analysis/pdf`}
              >
                Download report (PDF)
              </TargetAnalysisDownloadLink>
            )}

          <ActionMenuChiefLink resource={target} />
          {actions.archive && (
            <ArchiveMenuButton
              name={target.name}
              resourceId={target.id}
              resourceType="targets"
              archivedAt={target.archivedAt}
              status={target.status}
            />
          )}
          {actions.delete && (
            <ResourceDeleteButton
              isMenuItem
              resourceType="targets"
              resourceId={target.id}
              name={target.name}
              resourceStatus={target.status}
            />
          )}
        </MenuList>
      </Menu>
      {/* must be outside Menu otherwise pressing tab inside the Modal doesn't work */}
      {renameModalState.isOpen && target && (
        <RenameModal
          title="Rename deployment"
          defaultValue={target.name}
          onClose={renameModalState.onClose}
          updateFn={(name) => updateTarget(target.id, { name })}
          updating={updating}
          focusAfterCloseRef={menuBtnRef}
        />
      )}
      {apiShortcutsModalProps.isOpen && target && (
        <ApiShortcutsModal
          resource={{ id: target.id, resource_type: ResourceType.Targets }}
          onClose={apiShortcutsModalProps.onClose}
        />
      )}
    </>
  );
}
