export function singularize(str: string) {
  // hacky way to handle the word 'analyses'
  if (str.endsWith("analyses")) {
    return str.replace(/es$/, "is");
  }

  return str.replace(/s$/, "");
}

export function pluralize(str: string) {
  // hacky way to handle the word 'analysis'
  if (str.endsWith("analysis")) {
    return str.replace(/is$/, "es");
  }

  return str + "s";
}
