import { gql } from "@apollo/client";

import { targetOptionsFragment } from "../../../connectionOptionsFragments";

// this represents the response data we get back
// (so it includes read-only options like outputUrl)
export const targetFragment = gql`
  fragment TargetFragment on Target {
    id
    connectionId
    name
    archivedAt
    managed
    status
    statusError
    scopeId
    statusChangedAt
    lastUpdatedOutputAt
    lastUpdatedConfigAt
    lastReadInputAt
    humanReadable
    analysisConfig {
      geographies
      traits
    }
    customStructure {
      scopeCol
      outputCol
      isIdentityCol
      aggregation
      aggregationCriteria
    }
    filter {
      cohortMembership {
        cohortId
        eq
      }
      persona {
        personaId
        eq
        personaSetId
      }
      outcomeScore {
        outcomeId
        eq
        gt
        gte
        lt
        lte
        null
        nnull
      }
      outcomeProbability {
        outcomeId
        eq
        gt
        gte
        lt
        lte
        null
        nnull
      }
      outcomePercentile {
        outcomeId
        eq
        gt
        gte
        lt
        lte
        null
        nnull
      }
      recommenderRank {
        recommenderId
        eq
        gt
        gte
        lt
        lte
        null
        nnull
      }
      recommenderUncalibratedProbability {
        recommenderId
        eq
        gt
        gte
        lt
        lte
        null
        nnull
      }
      trait {
        name
        eq
        neq
        gt
        gte
        lt
        lte
        nnull
        null
        nin
        in
        matches
      }
    }
    limit {
      __typename
      ... on TargetLimitPercentile {
        method
        percentileOutcomeId: outcomeId
        percentileMin
        percentileMax
      }
      ... on TargetLimitRowCount {
        method
        rowCountOutcomeId: outcomeId
        threshold
        direction
      }
    }
    representation {
      __typename
      ... on TargetModesAggregated {
        mode
        aggregatedPreset: transformPreset
        # aliased because aggregate itself is a union string type that is not same as identified aggregate below.
        geographicAggregate: aggregate
        includeGeometry
      }
      ... on TargetModesIdentified {
        mode
        identifiedPreset: transformPreset
        # doesn't have to be aliased, but let's be consistent
        identifiedAggregate: aggregate
      }
      ... on TargetModesReferenced {
        mode
        referencedPreset: transformPreset
        reference {
          datasetId
          columnName
        }
        referenceDatasetId
      }
      ... on TargetModesHashed {
        mode
        hashedPreset: transformPreset
      }
    }
    ...TargetOptions
  }
  ${targetOptionsFragment}
`;
