import { Box, IconButton, Link, Select, Text, Tooltip } from "@chakra-ui/react";
import { Trash } from "@phosphor-icons/react";
import { groupBy } from "lodash";
import { ChangeEvent, Dispatch, SetStateAction, useMemo } from "react";

import { Trait } from "../../../../../hooks/useTraitsQuery";
import { colors } from "../../../../../styles/chakra-theme-v2";
import { Button } from "../../../../ui/Button";
import {
  ConditionRow,
  ConditionsBuilder,
  ConditionsBuilderProps,
  OPERATOR_LABELS,
} from "../../../../ui/ConditionsBuilder";
import { FormFieldset } from "../../../../ui/FormFieldset";
import { scopeToTargetFilterOptions } from "./scopeToTargetFilterOptions";
import {
  FilterableScopeDependencies,
  PayloadElemOption,
  TargetFilterRow,
  TargetFilterState,
  TargetFilterType,
} from "./types";

export const FILTER_INITIAL_STATE: TargetFilterState = [];

export const labels = {
  addFilter: "Add payload filter",
  payloadElem: "Payload element",
};

function FilterPropertyRowConditions({
  option,
  row,
  onChange,
}: {
  option: PayloadElemOption;
  row: TargetFilterRow;
  onChange: (row: TargetFilterRow) => void;
}) {
  // Build the list of operator configs for the conditions builder.
  const operators: ConditionsBuilderProps["operators"] = option.operators.map(
    (opr) => ({
      label: OPERATOR_LABELS[opr],
      operator: opr,
      picklist: option.picklist,
      options: option.options,
      defaultValue: option.defaultValue,
      min: option.min,
      max: option.max,
      step: option.step,
    })
  );

  const handleBuilderChange = (conditions: ConditionRow[]) => {
    onChange({
      ...row,
      conditions,
    });
  };

  return (
    <ConditionsBuilder
      // force condition builder state reinitialize when payload elem changes
      // otherwise we can end up with operators that don't apply to the new payload elem
      key={row.payload_elem_id + row.type}
      initialConditions={row.conditions}
      operators={operators}
      onChange={handleBuilderChange}
    />
  );
}

export const targetFilterTypeLabels: Record<TargetFilterType, string> = {
  cohortMembership: "Cohort membership",
  persona: "Persona set",
  outcomeScore: "Outcome score",
  outcomeProbability: "Outcome probability",
  outcomePercentile: "Outcome percentile",
  recommenderRank: "Recommender rank",
  recommenderUncalibratedProbability: "Recommender uncalibrated probability",
  trait: "Trait",
};

/** This component is a box representing the filtering on one target.
 * It shows a dropdown to select the payload element, as well as the entry boxes
 * for applying the desired filters (greater than, matches, etc)
 */
export function FilterPropertyRow({
  payloadOptions,
  onRemove,
  onChange,
  row,
  index,
}: {
  payloadOptions: PayloadElemOption[];
  row: TargetFilterRow;
  index: number;
  onRemove: () => void;
  onChange: (row: TargetFilterRow) => void;
}) {
  const selectedOption = payloadOptions.find(
    (p) => p.type === row.type && p.value === row.payload_elem_id
  );

  const payloadElemGroups = useMemo(() => {
    const groupedOptions = groupBy(payloadOptions, (p) => p.type);
    return Object.entries(groupedOptions).map(([type, options]) => ({
      label: targetFilterTypeLabels[type as TargetFilterType],
      options,
    }));
  }, [payloadOptions]);

  const ariaLabelId = index + 1;

  const handlePayloadElemChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = payloadOptions.find(
      (p) => p.value + p.type === e.target.value
    );

    if (!selectedOption)
      throw new Error(
        `Could not find payload option with value ${e.target.value}`
      );

    onChange({
      type: selectedOption.type,
      payload_elem_id: selectedOption.value,
      conditions: [
        {
          type: null,
          operator: null,
          value: null,
        },
      ],
    });
  };

  return (
    <Box
      pb={4}
      px={4}
      border="1px solid"
      borderColor={colors.fdy_gray[400]}
      borderRadius="md"
      bg="fdy_gray.100"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Text as="strong">{labels.payloadElem}</Text>
        <IconButton
          variant="icon"
          color="fdy_gray.700"
          aria-label={`Remove filter: ${selectedOption?.label}`}
          icon={<Trash />}
          onClick={onRemove}
        />
      </Box>

      <Select
        mb={4}
        mr={2}
        // Outcomes are a special case where the payload elem ID will be duplicated for each outcome
        // because there's an outcome score, probability, and percentile option.
        value={row.payload_elem_id + row.type}
        onChange={handlePayloadElemChange}
        aria-label={`Select payload element for filter condition ${ariaLabelId}`}
        autoFocus
      >
        {payloadElemGroups.map((group) => {
          return (
            <optgroup key={group.label} label={group.label}>
              {group.options.map((opt) => {
                // Combine value and type to make a unique value
                // because outcome scores, probability, and percentiles will
                // have the same payload elem ID. The option value must be
                // unique even among all groups.
                const value = opt.value + opt.type;
                return (
                  <option key={value} value={value} disabled={opt.disabled}>
                    {opt.label} - {group.label}
                  </option>
                );
              })}
            </optgroup>
          );
        })}
      </Select>

      {selectedOption && (
        <FilterPropertyRowConditions
          row={row}
          option={selectedOption}
          onChange={onChange}
        />
      )}
    </Box>
  );
}

export interface TargetFilterProps {
  scopeDependencies: FilterableScopeDependencies;
  filter: TargetFilterState;
  onChange: Dispatch<SetStateAction<TargetFilterState>>;
  traits?: Trait[];
  showOutcomeScore: boolean;
}

/**
 * Renders the inputs for modifying a target filter.
 */
export function TargetFilter({
  scopeDependencies,
  traits,
  filter,
  showOutcomeScore,
  onChange,
}: TargetFilterProps) {
  const payloadOptions = scopeToTargetFilterOptions(
    scopeDependencies,
    showOutcomeScore,
    traits
  );

  const disableAddPayloadElem = payloadOptions.every((o) => o.disabled);

  function handleRemoveFilterRow(index: number) {
    onChange((rows) => rows.filter((_, i) => i !== index));
  }

  function handleAddFilter() {
    // default to first possible non-disabled payload elem
    const firstPayloadElem = payloadOptions.find((o) => !o.disabled);
    if (!firstPayloadElem)
      throw new Error(
        "somehow tried to add a payload element though none are available"
      );

    onChange((rows) => [
      ...rows,
      {
        type: firstPayloadElem.type,
        payload_elem_id: firstPayloadElem.value,
        conditions: [
          // start with an empty condition just so the operator dropdown shows up
          {
            operator: null,
            value: null,
            type: null,
          },
        ],
      },
    ]);
  }

  function handleFilterPropertyRowChange(
    idx: number,
    targetFilterCondition: TargetFilterRow
  ) {
    onChange((rows) => {
      const newRows = [...rows];
      newRows[idx] = targetFilterCondition;
      return newRows;
    });
  }

  return (
    <FormFieldset
      legend="Select a payload element to filter on."
      hint={
        <>
          Rows must meet the following conditions in order to be included. This
          can be useful to exclude irrelevant portions of the output. Please
          take care: improper filtering can compromise predictive power. For
          detailed guidance in filtering deployments,{" "}
          <Link href="https://faraday.ai/docs/abstractions/deployments#deployment-filters">
            see our documentation.
          </Link>
        </>
      }
    >
      <Box display="grid" gap="4">
        {filter.map((row, i) => (
          <FilterPropertyRow
            key={i}
            index={i}
            payloadOptions={payloadOptions}
            row={row}
            onRemove={() => handleRemoveFilterRow(i)}
            onChange={(row) => {
              handleFilterPropertyRowChange(i, row);
            }}
          />
        ))}
      </Box>
      <Tooltip
        isDisabled={!disableAddPayloadElem}
        hasArrow
        label="The only filterable payload element is a persona set. It must finish building before you can choose a persona to filter by."
        shouldWrapChildren
      >
        <Button
          mt={3}
          variant="tertiary"
          disabled={disableAddPayloadElem}
          size="sm"
          onClick={handleAddFilter}
          analyticsName="add-filter"
        >
          {labels.addFilter}
        </Button>
      </Tooltip>
    </FormFieldset>
  );
}
