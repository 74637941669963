import {
  Box,
  Button,
  List,
  ListItem,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  VisuallyHidden,
} from "@chakra-ui/react";
import { ArchiveConfig, GraphEdge, ResourceType } from "@fdy/faraday-js";
import {
  CheckCircle,
  Clock,
  ClockCountdown,
  Graph,
  Icon,
  WarningCircle,
} from "@phosphor-icons/react";
import {
  MutationStatus,
  useMutation,
  useMutationState,
  useQueryClient,
} from "@tanstack/react-query";

import { resourceTypeAlias } from "../../constants/typeAlias";
import { useToast } from "../../hooks/useToast";
import { useFdyClient } from "../../services/FdyClientProvider";
import { useSojournerApolloClient } from "../../services/sojournerApolloClient";
import { colors } from "../../styles/chakra-theme-v2";
import { pluralize } from "../../utils/english";
import { plural } from "../../utils/formatters";
import { InlineButtons } from "./InlineButtons";
import { ModalV2 } from "./ModalV2";
import { MutedText } from "./MutedText";
import { PopoverInfoPill, PopoverInfoPillPart } from "./PopoverInfoPill";
import { useGraph } from "./ResourceGraphCard/graph-queries";
import { ResourceLink } from "./ResourceLink";

type ArchiveFn = (id: string, config: ArchiveConfig) => Promise<void>;

const archiveMutationKey = ["multi_archive"];

function useDynamicResourceArchive(resourceType: ResourceType) {
  const client = useFdyClient();

  const archiveFns: Record<ResourceType, ArchiveFn> = {
    // something wrong with `this` errors within faraday-js if you don't use arrow function
    outcomes: (...args) => client.outcomes.archiveOutcome(...args),
    cohorts: (...args) => client.cohorts.archiveCohort(...args),
    connections: (...args) => client.connections.archiveConnection(...args),
    datasets: (...args) => client.datasets.archiveDataset(...args),
    places: (...args) => client.places.archivePlace(...args),
    recommenders: (...args) => client.recommenders.archiveRecommender(...args),
    scopes: (...args) => client.scopes.archiveScope(...args),
    streams: (...args) => client.streams.archiveStream(...args),
    targets: (...args) => client.targets.archiveTarget(...args),
    traits: (...args) => client.traits.archiveTrait(...args),
    persona_sets: (...args) => client.personaSets.archivePersonaSet(...args),
    market_opportunity_analyses: (...args) =>
      client.marketOpportunityAnalyses.archiveMarketOpportunityAnalysis(
        ...args
      ),
    // this is easier than dealing with types when it's omitted
    accounts: () => {
      throw new Error("Not implemented");
    },
  };

  return useMutation({
    mutationKey: archiveMutationKey,
    mutationFn: (vars: { id: string; config: ArchiveConfig }) => {
      const fn = archiveFns[resourceType];
      return fn(vars.id, vars.config);
    },
  });
}

// recursively get downstream resources
function getDownstreamResources(resourceId: string, graphEdges: GraphEdge[]) {
  const downstreamResources: Map<string, GraphEdge> = new Map();

  function traverseDownstream(id: string) {
    const downstreamEdges = graphEdges.filter(
      (edge) => edge.upstream_id === id
    );

    downstreamEdges.forEach((edge) => {
      downstreamResources.set(edge.downstream_id, edge);
      traverseDownstream(edge.downstream_id);
    });
  }

  traverseDownstream(resourceId);

  return Array.from(downstreamResources.values());
}

const icons: Record<MutationStatus, Icon> = {
  idle: Clock,
  pending: ClockCountdown,
  success: CheckCircle,
  error: WarningCircle,
};

const iconColors: Record<MutationStatus, string> = {
  idle: colors.fdy_gray[500],
  pending: colors.fdy_gray[500],
  success: colors.fdy_green[500],
  error: colors.fdy_red[500],
};

const MutationStatusIcon = ({ status }: { status: MutationStatus }) => {
  if (status === "pending") {
    return <Spinner size="sm" />;
  }

  const Icon = icons[status] ?? WarningCircle;
  const fill = iconColors[status] ?? colors.fdy_gray[500];

  return <Icon weight="fill" fill={fill} />;
};

function useMultiArchiveMutation({
  resourceType,
}: {
  resourceType: ResourceType;
}) {
  const sojoClient = useSojournerApolloClient();
  const queryClient = useQueryClient();

  const statuses = useMutationState({
    filters: {
      mutationKey: archiveMutationKey,
    },
  });

  const archiveMutation = useDynamicResourceArchive(resourceType);

  const archiveAll = async (
    resources: {
      id: string;
      downstreams: GraphEdge[];
    }[]
  ): Promise<PromiseSettledResult<void>[]> => {
    const promises = resources.map((res) => {
      return archiveMutation.mutateAsync({
        id: res.id,
        config: {
          cascade_to: res.downstreams.map((d) => d.downstream_id),
        },
      });
    });

    const results = await Promise.allSettled(promises);

    queryClient.invalidateQueries({
      type: "active",
    });
    sojoClient.refetchQueries({
      include: "active",
    });
    // kopeng too?

    return results;
  };

  return {
    statuses,
    archiveAll,
  };
}

const generateArchiveMessage = ({
  successCount,
  errorCount,
}: {
  successCount: number;
  errorCount: number;
}) => {
  if (successCount > 0 && errorCount > 0) {
    return `${successCount} succeeded, ${errorCount} failed`;
  }
  if (successCount > 0) {
    return `${successCount} succeeded`;
  }
  if (errorCount > 0) {
    return `${errorCount} failed`;
  }
  return "No resources were archived";
};

function ArchivePrompt({
  ids,
  resourceType,
  resources: listResources,
  onCancel,
  onSettled,
}: {
  ids: string[];
  resourceType: ResourceType;
  resources: ListResource[];
  onCancel: () => void;
  onSettled: () => void;
}) {
  const toast = useToast();
  const { data: edges = [] } = useGraph();

  const { statuses, archiveAll } = useMultiArchiveMutation({ resourceType });

  const resources = ids.map((id) => {
    const resource = listResources.find((r) => r.id === id);
    if (!resource) throw new Error(`Resource with id ${id} not found`);
    return {
      id,
      resource,
      downstreams: getDownstreamResources(id, edges),
      mutationStatus: statuses.find(
        (s) => (s.variables as { id: string }).id === id
      ),
    };
  });

  const totalResources = resources.reduce(
    (acc, res) => acc + res.downstreams.length + 1,
    0
  );

  const resourceAlias = resourceTypeAlias(resourceType);

  const handleArchive = async () => {
    const results = await archiveAll(resources);

    const errors = results.filter((r) => r.status === "rejected");
    const successes = results.filter((r) => r.status === "fulfilled");
    const successCount = successes.length;
    const errorCount = errors.length;

    toast({
      status: successCount > 0 ? "success" : "error",
      title: successCount > 0 ? "Archiving completed" : "Archiving failed",
      description: generateArchiveMessage({ successCount, errorCount }),
    });

    onSettled();
  };

  return (
    <div>
      <Text mb={4}>
        Are you sure you want to archive the following{" "}
        {pluralize(resourceAlias)} <strong>and any dependents of them?</strong>
      </Text>

      <Table size="sm" mb={4}>
        <Thead>
          <Tr>
            <Th>Resource</Th>
            <Th>Dependents</Th>
            <Th width={32}>
              <VisuallyHidden>Archiving status</VisuallyHidden>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {resources.map((res) => {
            const content = (
              <Box maxWidth={300}>
                <Text mb={4}>
                  The following resources will also be archived because they
                  depend on <strong>{res.resource.name}</strong>.
                </Text>
                <List>
                  {res.downstreams.map((d) => (
                    <ListItem key={d.downstream_id}>
                      <ResourceLink
                        resource={{
                          id: d.downstream_id,
                          resource_type: d.downstream_type,
                          name: d.downstream_literate,
                        }}
                        showResourceIcon
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            );

            return (
              <Tr key={res.id}>
                <Td>
                  <ResourceLink
                    resource={{
                      id: res.id,
                      name: res.resource.name,
                      resource_type: resourceType,
                    }}
                    showResourceIcon
                  />
                </Td>
                <Td>
                  {res.downstreams.length > 0 ? (
                    <PopoverInfoPill popover={content} usePortal={false}>
                      <PopoverInfoPillPart
                        icon={Graph}
                        value={`${res.downstreams.length} ${plural(
                          res.downstreams.length,
                          "dependent",
                          "dependents"
                        )}`}
                      />
                    </PopoverInfoPill>
                  ) : (
                    <MutedText>None</MutedText>
                  )}
                </Td>
                <Td>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    {res.mutationStatus && (
                      <MutationStatusIcon status={res.mutationStatus.status} />
                    )}
                  </Box>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>

      <InlineButtons reverse>
        <Button
          variant="danger"
          onClick={handleArchive}
          isLoading={statuses.some((s) => s.status === "pending")}
          loadingText="Archiving..."
        >
          Archive {totalResources}{" "}
          {plural(totalResources, "resource", "resources")}
        </Button>

        <Button variant="tertiary" onClick={onCancel}>
          Cancel
        </Button>
      </InlineButtons>
    </div>
  );
}

type ListResource = {
  id: string;
  name: string;
};

export function MultiArchiveButton({
  ids,
  resourceType,
  resources,
  onSettled,
}: {
  ids: string[];
  resourceType: ResourceType;
  resources: ListResource[];
  onSettled: () => void;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen} variant="secondary" size="sm">
        Archive selected...
      </Button>
      {isOpen && (
        <ModalV2 isOpen title="Archive" onClose={onClose}>
          <ArchivePrompt
            ids={ids}
            resources={resources}
            resourceType={resourceType}
            onCancel={onClose}
            onSettled={() => {
              onClose();
              onSettled();
            }}
          />
        </ModalV2>
      )}
    </>
  );
}
