import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { MarketOpportunityAnalysis } from "@fdy/faraday-js";

import { useMarketOpportunityAnalysisListSuspenseQuery } from "../../../../hooks/api";
import { dollarsAbrev, largeNumber } from "../../../../utils/formatters";
import { Blankslate } from "../../../ui/Blankslate";
import {
  ConfigBreakdownTable,
  ConfigBreakdownTableRow,
} from "../../../ui/ConfigBreakdownTable";
import { PendingValue } from "../../../ui/PendingValue";
import {
  PopoverInfoPill,
  PopoverInfoPillPart,
} from "../../../ui/PopoverInfoPill";
import { ResourceIcon } from "../../../ui/ResourceIcon";
import { ResourceLink } from "../../../ui/ResourceLink";
import { ResourceStatusBadge } from "../../../ui/ResourceStatus/ResourceStatusBadge";
import { PipelineFragment as Scope } from "../../__generated__/PipelineFragment";
import {
  useScopePayloadOutcomesQuery,
  useScopePayloadPersonaSetsQuery,
} from "../scope-hooks";
import { MarketAnalysisActionMenu } from "./MarketAnalysisActionMenu";

function MarketAnalysisConfigPillPopover({
  analysis,
}: {
  analysis: MarketOpportunityAnalysis;
}) {
  const { data: outcomes } = useScopePayloadOutcomesQuery(analysis.scope_id);
  const { data: personaSets } = useScopePayloadPersonaSetsQuery(
    analysis.scope_id
  );

  const outcome = outcomes.find((o) => o.id === analysis.outcome_id);
  if (!outcome) throw new Error("Outcome not found");

  const personaSet = personaSets.find((p) => p.id === analysis.persona_set_id);
  if (!personaSet) throw new Error("Persona set not found");

  const persona = personaSet.personas?.find(
    (p) => p.id === analysis.persona_id
  );

  return (
    <ConfigBreakdownTable>
      <ConfigBreakdownTableRow
        header="Outcome"
        value={<ResourceLink resource={outcome} />}
        icon={ResourceIcon.outcomes}
      />

      {analysis.persona_set_id && (
        <ConfigBreakdownTableRow
          header="Persona set"
          value={
            <>
              <ResourceLink resource={personaSet} />
              {persona ? ` - ${persona.name}` : null}
            </>
          }
          icon={ResourceIcon.persona_sets}
        />
      )}
    </ConfigBreakdownTable>
  );
}

function MarketAnalysisConfigPill({
  analysis,
}: {
  analysis: MarketOpportunityAnalysis;
}) {
  return (
    <PopoverInfoPill
      popover={<MarketAnalysisConfigPillPopover analysis={analysis} />}
    >
      <PopoverInfoPillPart icon={ResourceIcon.outcomes} value={1} />
      {analysis.persona_set_id && (
        <PopoverInfoPillPart icon={ResourceIcon.persona_sets} value={1} />
      )}
    </PopoverInfoPill>
  );
}

export function MarketAnalysesTable({ scope }: { scope: Scope }) {
  const { data } = useMarketOpportunityAnalysisListSuspenseQuery({
    select: (data) => data.filter((a) => a.scope_id === scope.id), // we should add a param to filter by scope_id
  });
  if (data.length === 0) {
    return (
      <Blankslate
        title="No market analyses created yet"
        text="Create a new analysis to get started."
        filled
      />
    );
  }

  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Name</Th>
          <Th>Config</Th>
          <Th>Possible attainers</Th>
          <Th>Available value</Th>
          <Th>Status</Th>
          <Th textAlign="right">Actions</Th>
        </Tr>
      </Thead>
      <Tbody>
        {data.map((analysis) => (
          <Tr key={analysis.id}>
            <Td>{analysis.name}</Td>
            <Td>
              <MarketAnalysisConfigPill analysis={analysis} />
            </Td>
            <Td>
              <PendingValue
                value={
                  analysis.overall_report
                    ?.in_population_remaining_likely_attainers
                }
                formatter={largeNumber}
              />
            </Td>
            <Td>
              <PendingValue
                value={
                  analysis.overall_report
                    ?.in_population_remaining_attainment_value
                }
                formatter={dollarsAbrev}
              />
            </Td>
            <Td>
              <ResourceStatusBadge resource={analysis} />
            </Td>
            <Td textAlign="right">
              <MarketAnalysisActionMenu scope={scope} analysis={analysis} />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}
