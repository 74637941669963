import {
  TargetFilterMergePatch,
  TargetLimitMergePatch,
  TargetMergePatch,
  TargetMergePatchAnalysisConfig,
  TargetModesMergePatch,
  TargetOptionsMergePatch,
  TargetStructureTransformation,
} from "@fdy/faraday-js";

import {
  TargetFilterOutcomePercentileConditionsInput,
  TargetFilterOutcomeProbabilityConditionsInput,
  TargetFilterOutcomeScoreConditionsInput,
  TargetFilterRecommenderRankConditionsInput,
  TargetFilterRecommenderUncalibratedProbabilityConditionsInput,
} from "../../../__generated__/sojournerGlobalTypes";
import { assertArrayAndUnwrap } from "../../../utils/assertions";
import { ConnectionTypeInfo } from "../connectionUtils";
import { TargetStateForWire } from "./TargetForm/TargetFormAdvanced";

function targetFilterConditionsToPatchInputBase(
  conditions:
    | TargetFilterOutcomeScoreConditionsInput
    | TargetFilterRecommenderRankConditionsInput
    | TargetFilterRecommenderUncalibratedProbabilityConditionsInput
    | TargetFilterOutcomePercentileConditionsInput
    | TargetFilterOutcomeProbabilityConditionsInput
) {
  return {
    _eq: conditions.eq ?? undefined,
    _gte: conditions.gte ?? undefined,
    _lte: conditions.lte ?? undefined,
    _gt: conditions.gt ?? undefined,
    _lt: conditions.lt ?? undefined,
    _nnull: conditions.nnull ?? undefined,
    _null: conditions.null ?? undefined,
  };
}

// Convert local state target filter to a patch input, which requires a different format
// than the post input. This is nonsense. We should consider moving towards using REST for create/update.
function filterToPatch({ filter }: TargetStateForWire): TargetFilterMergePatch {
  return {
    cohort_membership:
      filter?.cohortMembership?.map((c) => {
        if (!c) throw new Error("Cohort membership item is null");
        return {
          cohort_id: c.cohortId,
          _eq: c.eq,
        };
      }) ?? null,
    outcome_percentile:
      filter?.outcomePercentile?.map((c) => {
        if (!c) throw new Error("Outcome percentile item is null");
        return {
          outcome_id: c.outcomeId,
          ...targetFilterConditionsToPatchInputBase(c),
        };
      }) ?? null,
    outcome_score:
      filter?.outcomeScore?.map((c) => {
        if (!c) throw new Error("Outcome score item is null");
        return {
          outcome_id: c.outcomeId,
          ...targetFilterConditionsToPatchInputBase(c),
        };
      }) ?? null,
    outcome_probability:
      filter?.outcomeProbability?.map((c) => {
        if (!c) throw new Error("Outcome probability item is null");
        return {
          outcome_id: c.outcomeId,
          ...targetFilterConditionsToPatchInputBase(c),
        };
      }) ?? null,
    persona:
      filter?.persona?.map((c) => {
        if (!c) throw new Error("Persona item is null");
        return {
          persona_id: c.personaId,
          persona_set_id: c.personaSetId,
          _eq: c.eq,
        };
      }) ?? null,
    trait:
      filter?.trait?.map((c) => {
        if (!c) throw new Error("Trait item is null");
        return {
          name: c.name,
          _eq: c.eq ?? undefined,
          _gte: c.gte ?? undefined,
          _lte: c.lte ?? undefined,
          _gt: c.gt ?? undefined,
          _lt: c.lt ?? undefined,
          _nnull: c.nnull ?? undefined,
          _in: c.in ? assertArrayAndUnwrap(c.in) : undefined,
          _nin: c.nin ? assertArrayAndUnwrap(c.nin) : undefined,
          _matches: c.matches ?? undefined,
          _neq: c.neq ?? undefined,
        };
      }) ?? null,
    recommender_rank:
      filter?.recommenderRank?.map((c) => {
        if (!c) throw new Error("Recommender rank item is null");
        return {
          recommender_id: c.recommenderId,
          ...targetFilterConditionsToPatchInputBase(c),
        };
      }) ?? null,
    recommender_uncalibrated_probability:
      filter?.recommenderUncalibratedProbability?.map((c) => {
        if (!c)
          throw new Error("Recommender uncalibrated probability item is null");
        return {
          recommender_id: c.recommenderId,
          ...targetFilterConditionsToPatchInputBase(c),
        };
      }) ?? null,
  };
}

function customStructureToPatchInput(
  state: TargetStateForWire
): TargetStructureTransformation[] | null {
  if (!state.customStructure) return null;

  return state.customStructure.map((structure) => {
    // snake cased keys are required for merge patch
    const struct: TargetStructureTransformation = {
      output_col: structure.outputCol ?? null,
      // convert enum to lowercase because the sojourner gql vs rest enums differ :(
      aggregation:
        structure.aggregation?.toLowerCase() as TargetStructureTransformation["aggregation"],
      scope_col: structure.scopeCol,
      aggregation_criteria: structure.aggregationCriteria ?? undefined,
      is_identity_col: structure.isIdentityCol ?? undefined,
    };

    return struct;
  });
}

/**
 * When updating/PATCHing a target, we allow for `null` values to be passed to unset values.
 * Local state stores this as '' because they are input values (and we don't want to swap
 * between controlled and uncontrolled inputs).
 * So convert empty strings to null before sending to the API.
 */
function optionsToPatch(
  state: TargetStateForWire,
  connectionTypeInfo: ConnectionTypeInfo
): TargetOptionsMergePatch {
  const emptiedOptions = { ...state.options };

  // for each key in the state, if the value is an empty string, set it to null
  for (const field in state.options) {
    if (emptiedOptions[field] === "") {
      emptiedOptions[field] = null;
    }
  }

  const options = {
    ...emptiedOptions,
    type: connectionTypeInfo.slug,
  } as TargetOptionsMergePatch;

  return options;
}

// Really fudging limit and representation types for now but their shapes don't need changing
// since they are transformed in lower components already.
// We should add better type safety at some point.

function representationToPatch({
  representation,
}: TargetStateForWire): TargetModesMergePatch {
  // this format should already be correct, luckily, but we need to cast it to unknown
  return representation as unknown as TargetModesMergePatch;
}

function limitToPatch({
  limit,
}: TargetStateForWire): TargetLimitMergePatch | null {
  if (!limit) return null;
  // this is also already in the ideal shape, but we need to cast it
  return limit as unknown as TargetLimitMergePatch;
}

function analysisToPatch({
  analysisConfig,
}: TargetStateForWire): TargetMergePatchAnalysisConfig | null {
  if (!analysisConfig) return null;
  // this is also already in the ideal shape, but we need to cast it
  return {
    traits: analysisConfig.traits ?? null,
    geographies:
      analysisConfig.geographies?.map((g) => g?.toLowerCase()) ?? null,
  } as unknown as TargetMergePatchAnalysisConfig;
}

/**
 * Return the Merge Patch input for updating a target.
 * We have to ensure we pass nulls instead of undefined for fields that are to be unset.
 */
export function targetStateToPatchInput(
  state: TargetStateForWire,
  connectionTypeInfo: ConnectionTypeInfo
): TargetMergePatch {
  return {
    representation: representationToPatch(state),
    human_readable: state.humanReadable,
    filter: filterToPatch(state),
    limit: limitToPatch(state),
    custom_structure: customStructureToPatchInput(state),
    options: optionsToPatch(state, connectionTypeInfo),
    analysis_config: analysisToPatch(state),
  };
}
