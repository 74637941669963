import { Dataset, Resource } from "@fdy/faraday-js";
import { camelCase } from "lodash";
import pick from "lodash/pick";
import upperFirst from "lodash/upperFirst";

import { getApiBaseUrl } from "../../../services/getApiBaseUrl";
import { singularize } from "../../../utils/english";
import uuid from "../../../utils/uuid";
import { HttpMethod, HttpRequest } from "./requestToHttpSnippet";
import { SojournerApiSpecJson } from "./SojournerApiSpecJson";

export type RequestBuilderResource = Pick<Resource, "id" | "resource_type">;

type SchemaName = keyof SojournerApiSpecJson["components"]["schemas"];

function isVariableSchema(
  spec: SojournerApiSpecJson,
  schema: string
): schema is SchemaName {
  return schema in spec.components.schemas;
}

/**
 * Given a mostly details about a request for the Faraday API, returns a Request object.
 * Conditionally includes a body and API key.
 *
 * Note: we do some uncommon things here like turn patch into merge-patch.
 */
function buildRequest(
  url: string,
  method: HttpMethod,
  body: Record<string, unknown> | undefined,
  apiKey: string | null
): HttpRequest {
  // Ideally pull headers from spec instead of hardcoding but this is fine for now
  const headers: HttpRequest["headers"] = {
    accept: "application/json",
    authorization: `Bearer ${apiKey ?? "<token>"}`,
  };

  if (body) {
    headers["content-type"] =
      "application/json" + (method === "PATCH" ? "+merge-patch" : "");
  }

  // Since our snippet examples POST with existing names, we'll append (copy) to
  // the name to avoid conflicts.
  if (body && "name" in body && method === "POST") {
    body.name = body.name + " [copy]";
  }

  const request: HttpRequest = {
    url,
    method,
    headers,
  };

  if (body) request.body = body;

  return request;
}

function isDataset(obj: RequestBuilderResource): obj is Dataset {
  return obj.resource_type === "datasets";
}

function buildBodyFromSchema({
  schema,
  resource,
}: {
  schema: SojournerApiSpecJson["components"]["schemas"][SchemaName];
  resource: RequestBuilderResource;
}) {
  if (typeof schema === "string" || !("properties" in schema)) return;

  // Exception for datasets because they contain readonly fields that shouldn't
  // be sent back to the server.
  if (isDataset(resource)) {
    for (const stream in resource.output_to_streams) {
      // Ignoring this because we want the key gone anyway. It's ts error because it's readonly
      // and expected to be part of a dataset GET response, but we want it gone for the POST/PATCH request.
      // To fix this, we should inspect the schema for readonly fields and
      // remove them from the resource, but we don't have that meta info in the
      // json spec right now.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete resource.output_to_streams[stream].stream_id;
      // Classic is optional, so ts doesn't care if it's deleted.
      delete resource.output_to_streams[stream].classic;
    }
  }

  const body = pick(resource, Object.keys(schema.properties));

  return body;
}

/**
 * Looks up the operation for a given method on a resource, and builds a request object
 * from the spec and the resource's schema.
 *
 * Returns undefined if the operation does not exist.
 */
function buildRequestFromSpecAndMethod({
  spec,
  resource,
  method,
  apiKey,
  baseUrl,
}: {
  spec: SojournerApiSpecJson;
  resource: RequestBuilderResource;
  method: HttpMethod;
  apiKey: string | null;
  baseUrl: string;
}): HttpRequest | undefined {
  const { resource_type } = resource;

  const singularResourceType = singularize(resource_type);
  const camelCaseResourceType = camelCase(singularResourceType);
  let param = "";

  if (resource_type === "streams" && method === "POST") {
    param = "/{stream_name}";
  } else if (
    resource_type === "streams" &&
    (method === "GET" || method === "DELETE")
  ) {
    param = "/{stream_id_or_name}";
  } else {
    param = method === "POST" ? "" : `/{${singularResourceType}_id}`;
  }

  const endpoint = `/${resource_type}${param}`;

  const resourceEndpoint = spec.paths[endpoint as keyof typeof spec.paths];

  if (!resourceEndpoint) {
    console.warn(`No endpoint found for ${endpoint}`);
    return;
  }

  // FIXME: why is this `never`??
  const methodEndpoint = method.toLowerCase() as keyof typeof resourceEndpoint;
  const operation =
    methodEndpoint in resourceEndpoint
      ? resourceEndpoint[methodEndpoint]
      : undefined;

  // ensure that the operation exists for the method, otherwise we should not continue
  if (!operation) {
    console.warn(`No operation found for ${method} on ${endpoint}`);
    return;
  }

  // Prepare the request body

  // patch -> CohortMergePatch, post -> CohortPost, etc
  const correctedMethod =
    method === "PATCH" ? "mergePatch" : method.toLowerCase();
  const variableSchema =
    upperFirst(camelCaseResourceType) + upperFirst(correctedMethod);

  const schema = isVariableSchema(spec, variableSchema)
    ? spec.components.schemas[variableSchema]
    : undefined;

  const body = schema ? buildBodyFromSchema({ schema, resource }) : undefined;

  // Prepare the url param
  let variableParam = method === "POST" ? "" : `/${resource.id}`;
  // streams POST requests need a unique name to avoid conflicts
  if (resource_type === "streams" && method === "POST" && "name" in resource) {
    // TODO: should use {resource.name}_copy instead
    variableParam = `/event_stream_copy_${uuid().slice(0, 5)}`;
  }
  const url = `${baseUrl}/${resource_type}${variableParam}`;

  return buildRequest(url, method, body, apiKey);
}

/**
 * Given an OpenAPI spec and a resource, returns an array of Request objects
 * representing a handful of basic CRUD requests for that resource.
 *
 * - Inserts the API key into the request headers if it is provided.
 * - Uses the resource's schema to generate a request body for POST and PATCH
 */
export function openApiSpecToRequestSnippets({
  spec,
  resource,
  apiKey,
  baseUrl = getApiBaseUrl(),
}: {
  spec: SojournerApiSpecJson;
  resource: RequestBuilderResource;
  apiKey: string | null;
  baseUrl: string;
}): HttpRequest[] {
  const requests: HttpRequest[] = [];

  const methods: HttpMethod[] = ["POST", "GET", "PATCH", "DELETE"];

  for (const method of methods) {
    const request = buildRequestFromSpecAndMethod({
      spec,
      resource,
      method,
      apiKey,
      baseUrl,
    });
    if (request) requests.push(request);
  }

  return requests;
}
