import { gql } from "@apollo/client";

export const outcomeFragment = gql`
  fragment OutcomeFragment on Outcome {
    id
    name
    attainmentCohortId
    attainmentCohortName
    eligibleCohortId
    eligibleCohortName
    attritionCohortId
    attritionCohortName
    archivedAt
    status
    statusError
    rocAuc
    liftValue
    reportUrl
    featureBlocklist
    predictors {
      blocked {
        providers
      }
    }
    lastUpdatedOutputAt
    lastUpdatedConfigAt
    lastReadInputAt
    createdAt
    biasMitigation {
      age
      gender
    }
    preview
    predictionMode
  }
`;
