import { ReactNode } from "react";

import { AccordionV2 } from "./AccordionV2";

/**
 * Renders a chakra accordion for a single collapsible item which is always
 * toggleable
 */
export function Collapsible({
  children,
  title,
  isOpen,
}: {
  children: ReactNode;
  title: string;
  isOpen?: boolean;
}) {
  return (
    <AccordionV2 defaultIndex={[isOpen ? 0 : -1]} allowToggle>
      <AccordionV2.Item title={title}>{children}</AccordionV2.Item>
    </AccordionV2>
  );
}
