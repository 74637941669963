import {
  Box,
  Divider,
  Flex,
  Input,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FormEvent, useState } from "react";

import { namePatternHelpText } from "../../../datasets/DatasetsShowPage/DatasetForm/events/validateOutputToStreamEvent";
import { Checkbox } from "../../../ui/Checkbox";
import { FormField } from "../../../ui/FormField";
import { FormFieldset } from "../../../ui/FormFieldset";
import { FormFieldStack } from "../../../ui/FormFieldStack";
import { Notice } from "../../../ui/Notice";
import { PipelineFragment as Scope } from "../../__generated__/PipelineFragment";
import { ScopePopulationBreakdown } from "../../ScopePopulationBreakdown";
import {
  useScopePayloadOutcomesQuery,
  useScopePayloadPersonaSetsQuery,
} from "../scope-hooks";

export type MarketAnalysisFormState = {
  name: string;
  outcome_id: string;
  persona_set_id: string | null;
  persona_id: string | null;
};

function ScopeOutcomeSelect({
  value,
  onChange,
  scope,
}: {
  value: string;
  onChange: (value: string) => void;
  scope: Scope;
}) {
  const { data } = useScopePayloadOutcomesQuery(scope.id);
  return (
    <Select onChange={(e) => onChange(e.target.value)} value={value} required>
      {data
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((outcome) => (
          <option key={outcome.id} value={outcome.id}>
            {outcome.name}
          </option>
        ))}
    </Select>
  );
}

type MarketAnalysisPersonaState = Pick<
  MarketAnalysisFormState,
  "persona_id" | "persona_set_id"
>;

function ScopePersonaSetFields({
  state,
  onChange,
  scope,
}: {
  state: MarketAnalysisPersonaState;
  scope: Scope;
  onChange: (args: MarketAnalysisPersonaState) => void;
}) {
  const { data } = useScopePayloadPersonaSetsQuery(scope.id);

  const canAddPersonas = scope.payload.personaSetIds.length > 0;

  const sortedPersonaSets = data.sort((a, b) => a.name.localeCompare(b.name));
  const selectedPersonaSet = data.find((p) => p.id === state.persona_set_id);

  const sortedPersonas = selectedPersonaSet?.personas?.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return (
    <Flex
      sx={{
        gap: 4,
      }}
    >
      <FormField
        label="Persona set"
        suffix="optional"
        helpText="Enables additional analysis."
      >
        <Select
          onChange={(e) =>
            onChange({ persona_set_id: e.target.value, persona_id: null })
          }
          value={state.persona_set_id ?? ""}
          disabled={!canAddPersonas}
          placeholder={
            canAddPersonas
              ? "Select persona set..."
              : "No persona sets in pipeline payload"
          }
        >
          {sortedPersonaSets.map((personaSet) => (
            <option key={personaSet.id} value={personaSet.id}>
              {personaSet.name}
            </option>
          ))}
        </Select>
      </FormField>

      <FormField
        label="Persona"
        helpText="Is there one persona of interest in this set?"
        suffix="optional"
      >
        <Select
          onChange={(e) =>
            onChange({
              persona_set_id: state.persona_set_id,
              persona_id: e.target.value,
            })
          }
          value={state.persona_id ?? ""}
          disabled={!state.persona_set_id || !canAddPersonas}
          placeholder={
            selectedPersonaSet
              ? "Select persona..."
              : sortedPersonas?.length === 0
              ? "No personas in set yet"
              : "Select a persona set first"
          }
        >
          {sortedPersonas?.map((persona) => (
            <option key={persona.id} value={persona.id}>
              {persona.name}
            </option>
          ))}
        </Select>
      </FormField>
    </Flex>
  );
}

function ConfirmPopulationCheckboxes({
  scope,
  defaultChecked,
}: {
  scope: Scope;
  defaultChecked?: boolean;
}) {
  return (
    <Flex gap={8}>
      <Box w="50%">
        <FormFieldset legend="Confirm population" size="sm">
          <VStack spacing={4}>
            <Checkbox
              isRequired
              alignItems="flex-start"
              defaultChecked={defaultChecked}
            >
              {/* TODO: fix extra space above text. Move text to checkbox component?  */}
              The population shown on the right represents my overall market.
            </Checkbox>
            <Checkbox
              isRequired
              alignItems="flex-start"
              defaultChecked={defaultChecked}
            >
              My existing customers have <strong>not</strong> been excluded from
              the population on the right.
            </Checkbox>
          </VStack>
        </FormFieldset>
      </Box>

      <Box w="50%">
        <ScopePopulationBreakdown scope={scope} />
      </Box>
    </Flex>
  );
}

export function MarketAnalysisForm({
  scope,
  onSave,
  formId,
  initialState,
}: {
  scope: Scope;
  onSave: (state: MarketAnalysisFormState) => void;
  formId: string;
  initialState?: MarketAnalysisFormState;
}) {
  const [formState, setFormState] = useState<MarketAnalysisFormState>({
    name: initialState?.name ?? "",
    outcome_id: initialState?.outcome_id ?? scope.payload.outcomeIds[0],
    persona_set_id: initialState?.persona_set_id ?? null,
    persona_id: initialState?.persona_id ?? null,
  });

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    onSave(formState);
  };

  return (
    <form id={formId} onSubmit={handleSubmit}>
      <FormFieldStack>
        <Text fontSize="fdy_lg">
          This analysis helps you measure and explore your penetration/progress
          to date &mdash; and discover the size and location of remaining
          opportunity in your market.
        </Text>

        <Notice
          variant="info"
          title="You should only use this analysis with a pipeline that represents customer acquisition."
          description={
            <>
              The steps below will help guide you in confirming this.
              {/* fixme: link this */}
              {/* Read more about market opportunity analysis */}
            </>
          }
        />

        <Divider my={0} />

        <ConfirmPopulationCheckboxes
          defaultChecked={Boolean(initialState)}
          scope={scope}
        />

        <Divider my={0} />

        <FormField
          label="Outcome"
          helpText="This outcome must represent “becoming a customer.”"
        >
          <ScopeOutcomeSelect
            value={formState.outcome_id}
            onChange={(value) =>
              setFormState({ ...formState, outcome_id: value })
            }
            scope={scope}
          />
        </FormField>

        <ScopePersonaSetFields
          scope={scope}
          state={formState}
          onChange={(personaState) =>
            setFormState((prev) => ({
              ...prev,
              ...personaState,
            }))
          }
        />

        <Divider my={0} />

        <FormField
          label="Name"
          helpText="Give your analysis a name — this will also appear on downloadable assets."
        >
          <Input
            value={formState.name}
            onChange={(e) =>
              setFormState((prev) => ({ ...prev, name: e.target.value }))
            }
            required
            title={namePatternHelpText}
          />
        </FormField>
      </FormFieldStack>
    </form>
  );
}
