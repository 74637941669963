import { useRouter } from "react-router5";

import { ROUTE_NAMES } from "../../constants/routeNames";
import { useRecommenderCreateMutation } from "../../hooks/api";
import { useApiErrorToaster } from "../../hooks/useApiErrorToaster";
import {
  HUBSPOT_USER_CREATED_RECOMMENDER,
  useHubSpotEvent,
} from "../../hooks/useHubspotEvent";
import { useToast } from "../../hooks/useToast";
import {
  RecommenderFormStateToWire,
  RecommendersForm,
} from "./RecommendersForm";
import { RecommendersSidebarLayout } from "./RecommendersSidebarLayout";

export function RecommendersNewPage() {
  const toast = useToast();
  const track = useHubSpotEvent();

  const router = useRouter();

  const createRecommenderMutation = useRecommenderCreateMutation();
  useApiErrorToaster(createRecommenderMutation.error);

  async function handleSave(state: RecommenderFormStateToWire) {
    const recommender = await createRecommenderMutation.mutateAsync({
      ...state,
      preview: state.preview ?? false,
    });

    track(HUBSPOT_USER_CREATED_RECOMMENDER, {
      resource_id: recommender.id,
      resource_name: recommender.name,
    });

    toast({
      status: "success",
      title: `${recommender.name} successfully created!`,
      description: recommender.preview
        ? "Recommender in preview mode. It will not generate models until enabled."
        : "It may take a few hours before this recommender is available. We'll send you an email when it's ready.",
    });

    router.navigate(ROUTE_NAMES.RECOMMENDERS_SHOW, {
      id: recommender.id,
    });
  }

  const title = "New recommender";
  return (
    <RecommendersSidebarLayout
      title={title}
      lastCrumb={{
        label: title,
        routeName: ROUTE_NAMES.RECOMMENDERS_NEW,
      }}
    >
      <RecommendersForm
        onSave={handleSave}
        saving={createRecommenderMutation.isPending}
      />
    </RecommendersSidebarLayout>
  );
}
