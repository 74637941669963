import { gql } from "@apollo/client";
import { useRoute, useRouter } from "react-router5";

import { OutcomePostInput } from "../../__generated__/sojournerGlobalTypes";
import { ROUTE_NAMES } from "../../constants/routeNames";
import {
  HUBSPOT_USER_CREATED_OUTCOME,
  useHubSpotEvent,
} from "../../hooks/useHubspotEvent";
import { useToast } from "../../hooks/useToast";
import { useSojournerMutation } from "../../services/sojournerApolloClient";
import { UsageWarningNotice } from "../ui/UsageWarningNotice";
import {
  CreateOutcomeMutationV2,
  CreateOutcomeMutationV2Variables,
} from "./__generated__/CreateOutcomeMutationV2";
import { initialFormState, OutcomeForm } from "./OutcomeForm";
import { outcomeFragment } from "./outcomeFragment";
import { OutcomeLayout } from "./OutcomeLayout";
import { outcomesQuery } from "./outcomesQuery";
import { useCreateOutcomeWithDuplicateWarning } from "./useCreateOutcomeWithDuplicateWarning";
import { OutcomeFormState } from "./useUpdateOutcome";

export const createOutcomeMutationV2 = gql`
  mutation CreateOutcomeMutationV2($outcome: OutcomePostInput!) {
    createOutcome(outcomePostInput: $outcome) {
      ...OutcomeFragment
    }
  }
  ${outcomeFragment}
`;

function outcomeStateToPostInput(state: OutcomeFormState): OutcomePostInput {
  return {
    name: state.name,
    attainmentCohortId: state.attainment_cohort_id,
    attritionCohortId:
      state.attrition_cohort_id === "" ? undefined : state.attrition_cohort_id,
    eligibleCohortId:
      state.eligible_cohort_id === "" ? undefined : state.eligible_cohort_id,
    featureBlocklist: state.feature_blocklist,
    predictors: state.predictors,
    biasMitigation: state.bias_mitigation,
    preview: state.preview,
    predictionMode: state.prediction_mode,
  };
}

export function OutcomesNewPage() {
  const { navigate } = useRouter();
  const { route } = useRoute();
  const track = useHubSpotEvent();
  const toast = useToast();
  const { createWithWarning, findingDuplicate } =
    useCreateOutcomeWithDuplicateWarning();

  const [createOutcome, { loading }] = useSojournerMutation<
    CreateOutcomeMutationV2,
    CreateOutcomeMutationV2Variables
  >(createOutcomeMutationV2, {
    // add new outcome to list page after creation
    refetchQueries: [{ query: outcomesQuery }],
    onCompleted(data) {
      if (data.createOutcome) {
        track(HUBSPOT_USER_CREATED_OUTCOME, {
          resource_id: data.createOutcome.id,
          resource_name: data.createOutcome.name,
        });
        // notify user of success via toast
        toast({
          title: `${data.createOutcome.name} successfully created!`,
          description: data.createOutcome.preview
            ? "Outcome in preview mode. It will not generate models until enabled."
            : "It may take a few hours before this outcome is available. We'll send you an email when it's ready.",
          status: "success",
        });

        // redirect to show page
        navigate(ROUTE_NAMES.OUTCOMES_DEFINITION, {
          outcome: data.createOutcome.id,
        });
      }
    },
  });

  // initialize the form state with query params for duplication feature
  // Note: route params _can_ be undefined, so we need to check for that
  const params = (route?.params as OutcomeFormState) ?? {};

  const initialState: OutcomeFormState = {
    ...initialFormState,
    ...params,
  };

  async function onSave(state: OutcomeFormState) {
    const input = outcomeStateToPostInput(state);

    await createWithWarning({
      input,
      onCreate: () => {
        createOutcome({
          variables: {
            outcome: input,
          },
        });
      },
      loading,
    });
  }

  return (
    <OutcomeLayout
      crumbs={[
        {
          label: "Outcomes",
          routeName: ROUTE_NAMES.OUTCOMES,
        },
        {
          label: "New outcome",
          routeName: ROUTE_NAMES.OUTCOMES_NEW,
        },
      ]}
      backBtnProps={{
        label: "Back to Outcomes",
        routeName: ROUTE_NAMES.OUTCOMES,
      }}
    >
      <UsageWarningNotice resource={"outcomes"} />
      <OutcomeForm
        initialState={initialState}
        onSave={onSave}
        saving={findingDuplicate || loading}
      />
    </OutcomeLayout>
  );
}
