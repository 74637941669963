import {
  ApolloCache,
  DocumentNode,
  gql,
  NormalizedCacheObject,
  useApolloClient,
} from "@apollo/client";
import { ButtonProps, MenuItem, Text, useDisclosure } from "@chakra-ui/react";
import { Resource, ResourceStatus, ResourceType } from "@fdy/faraday-js";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import { ResourceStatus as GqlResourceStatus } from "../../__generated__/sojournerGlobalTypes";
import { resourceTypeToGqlTypename } from "../../constants/gqlTypenameToResourceType";
import { typeAlias } from "../../constants/typeAlias";
import { useToast } from "../../hooks/useToast";
import { useSojournerApolloClient } from "../../services/sojournerApolloClient";
import { AnimatedZapLogo } from "./AnimatedZapLogo";
import { Button } from "./Button";
import { InlineButtons } from "./InlineButtons";
import { ModalV2 } from "./ModalV2";
import { ResourceLink } from "./ResourceLink";
import {
  getGraphForResource,
  GraphDirection,
  sortByResourceType,
  useResourceGraphQuery,
} from "./useResourceGraph";

// Convert enum to string literal type for ease of use in props
export type ResourceTypeName = `${ResourceType}`;

/**
 * Our delete mutations registry keyed on resource type
 */
export const deleteMutations: Partial<Record<ResourceType, DocumentNode>> = {
  cohorts: gql`
    mutation DeleteCohortMutation($id: ID!) {
      deleteCohort(cohortId: $id)
    }
  `,
  connections: gql`
    mutation DeleteConnectionMutation($id: ID!) {
      deleteConnection(connectionId: $id)
    }
  `,
  datasets: gql`
    mutation DeleteDatasetMutation($id: ID!) {
      deleteDataset(datasetId: $id)
    }
  `,
  market_opportunity_analyses: gql`
    mutation DeleteMarketOpportunityAnalysisMutation($id: ID!) {
      deleteMarketOpportunityAnalysis(marketOpportunityAnalysisId: $id)
    }
  `,
  outcomes: gql`
    mutation DeleteOutcomeMutation($id: ID!) {
      deleteOutcome(outcomeId: $id)
    }
  `,
  persona_sets: gql`
    mutation DeletePersonaSetMutation($id: ID!) {
      deletePersonaSet(personaSetId: $id)
    }
  `,
  scopes: gql`
    mutation DeleteScopeMutation($id: ID!) {
      deleteScope(scopeId: $id)
    }
  `,
  streams: gql`
    mutation DeleteStreamMutation($id: String!) {
      deleteStream(streamIdOrName: $id)
    }
  `,
  targets: gql`
    mutation DeleteTargetMutation($id: ID!) {
      deleteTarget(targetId: $id)
    }
  `,
  traits: gql`
    mutation DeleteTraitMutation($id: ID!) {
      deleteTrait(traitId: $id)
    }
  `,
  recommenders: gql`
    mutation DeleteRecommenderMutation($id: ID!) {
      deleteRecommender(recommenderId: $id)
    }
  `,
};

export interface ResourceDeleteButtonProps {
  resourceType: ResourceTypeName;
  resourceId: string;
  name: string;
  isMenuItem?: boolean;
  buttonProps?: ButtonProps;
  /** Enables re-routing and other side effects */
  onDeleteComplete?: () => void;
  dontEvictFromCache?: boolean;
  usesKopengCache?: boolean;
  resourceStatus: GqlResourceStatus | ResourceStatus;

  /**
   * The react-query key for the list of resources to cleanup after deletion.
   * Used when the resource cached by react-query, not graphql.
   * It only cleans up list pages, not detail pages, but that shouldn't be a
   * problem because you can't delete a resource if another resource depends on it.
   */
  listQueryKey?: string[];
}

export function ResourceDeleteButton({
  isMenuItem,
  resourceType,
  resourceId: id,
  name,
  buttonProps,
  onDeleteComplete,
  dontEvictFromCache,
  usesKopengCache,
  resourceStatus,
  listQueryKey,
}: ResourceDeleteButtonProps) {
  const kopengClient = useApolloClient(); // for legacy caching
  const sojoClient = useSojournerApolloClient();
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const queryClient = useQueryClient();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const resourceNameOrAlias = name ?? typeAlias[resourceType];

  async function handleDelete() {
    const mutation = deleteMutations[resourceType];

    if (!mutation) {
      throw new Error(`No delete mutation for resource '${resourceType}'`);
    }

    try {
      setLoading(true);

      // FIXME (maybe) - do we need to invoke the client directly? Can we use a hook?
      await sojoClient.mutate({
        mutation,
        variables: { id },
        // TODO: decide we want to delete this:
        // For now, we are simply updating the cache instead to avoid a race condition
        // with the db being locked during deletion.
        // refetchQueries,
        update: (cache: ApolloCache<NormalizedCacheObject>) => {
          if (usesKopengCache) return;
          if (!dontEvictFromCache) {
            cacheEvict({ cache, resourceType, id });
          }
        },
      });

      if (usesKopengCache && !dontEvictFromCache) {
        cacheEvict({
          cache: kopengClient.cache,
          resourceType,
          id,
        });
      }

      // if the resource is cached by react-query, remove it from the cache for the list page
      if (listQueryKey) {
        queryClient.setQueryData<Resource[]>(listQueryKey, (oldData) => {
          // make sure the query key is an array of resources of the same type we expect
          if (
            Array.isArray(oldData) &&
            oldData.every((r) => r.resource_type === resourceType)
          ) {
            return oldData.filter((item) => item.id !== id);
          }
          return oldData;
        });
      }

      // NOTE - we can't update state after the resource is removed from the cache, otherwise
      // we get a React error (the component is likely to become unmounted).
      // setLoading(false);

      // Permit an optional callback (ex. to re-route the page)
      if (onDeleteComplete) {
        onDeleteComplete();
      }

      toast({
        status: "success",
        title: "Deletion successful",
        description: `${resourceNameOrAlias} has been deleted`,
      });
      // NOTE - we can't update state after the resource is removed from the cache, otherwise
      // we get a React error (the component is likely to become unmounted).
      // onClose();
    } catch (e) {
      setLoading(false);

      // FIXME: tell user why they can't delete and what they need to do
      // right now sojo just returns 202 on success and 500 on failure - need response codes

      toast({
        status: "error",
        title: "Deletion failed",
        description: `Failed to delete ${resourceNameOrAlias}. Check for dependencies and delete those first.`,
      });
    }

    onClose();
  }

  return (
    <>
      {isMenuItem ? (
        <MenuItem onClick={onOpen} color="fdy_red.500">
          Delete...
        </MenuItem>
      ) : (
        <Button
          {...buttonProps}
          onClick={onOpen}
          analyticsName="resource-delete"
        >
          Delete {typeAlias[resourceType]}
        </Button>
      )}

      {isOpen && (
        <DeleteModal
          resourceId={id}
          resourceType={resourceType}
          isOpen={isOpen}
          onClose={onClose}
          loading={loading}
          handleDelete={handleDelete}
          name={name}
          resourceStatus={resourceStatus}
        />
      )}
    </>
  );
}

/**
 * Evict a resource from the given apollo cache
 */
export function cacheEvict({
  resourceType,
  cache,
  id,
}: {
  id: string;
  cache: ApolloCache<object>;
  resourceType: ResourceTypeName;
}): boolean {
  const normalizedId = cache.identify({
    id,
    __typename: resourceTypeToGqlTypename(resourceType),
  });

  const success = cache.evict({
    id: normalizedId,
    broadcast: false, // IMPORTANT: prevent a refresh of all active queries
  });

  // Per the apollo docs: Evicting an object often makes other cached objects unreachable.
  // Because of this, you should call cache.gc after evicting one or more objects from the cache.
  cache.gc();

  return success;
}

function DeleteModal({
  isOpen,
  onClose,
  loading,
  handleDelete,
  resourceType,
  resourceId,
  name,
  resourceStatus,
}: {
  resourceType: ResourceTypeName;
  resourceId: string;
  isOpen: boolean;
  onClose: () => void;
  loading: boolean;
  handleDelete: () => void;
  name: string;
  resourceStatus: ResourceStatus | GqlResourceStatus;
}) {
  const { data, loading: graphLoading } = useResourceGraphQuery({
    fetchPolicy: "network-only",
  });

  const relevantGraph = data?.graph
    ? getGraphForResource({
        edges: data.graph,
        resourceId,
        direction: GraphDirection.DOWNSTREAM,
      }).sort(sortByResourceType(GraphDirection.DOWNSTREAM))
    : [];

  const isResourceBuilding =
    resourceStatus === ResourceStatus.Starting ||
    resourceStatus === ResourceStatus.Running ||
    resourceStatus === GqlResourceStatus.STARTING ||
    resourceStatus === GqlResourceStatus.RUNNING;

  return (
    <ModalV2
      title={`Delete ${typeAlias[resourceType]}`}
      isOpen={isOpen}
      onClose={onClose}
      analyticsStackName="resource-delete"
      footer={
        <InlineButtons reverse>
          <Button
            isDisabled={
              loading ||
              graphLoading ||
              !!relevantGraph?.length ||
              isResourceBuilding
            }
            isLoading={loading}
            variant="danger"
            onClick={handleDelete}
            loadingText="Deleting..."
            analyticsName="confirm"
          >
            Delete
          </Button>
          <Button onClick={onClose} variant="tertiary" analyticsName="cancel">
            Cancel
          </Button>
        </InlineButtons>
      }
    >
      {graphLoading && <AnimatedZapLogo />}
      {!graphLoading && !relevantGraph?.length && (
        <Text>
          Are you sure you want to permanently delete <strong>{name}</strong>?
        </Text>
      )}
      {isResourceBuilding && (
        <Text color="fdy_red.500" pb={4}>
          <strong>{name}</strong> cannot be deleted while it is building. Please
          wait and try again.
        </Text>
      )}
      {!graphLoading && !!relevantGraph?.length && (
        <>
          <Text color="fdy_red.500" pb={4}>
            <strong>{name}</strong> cannot be deleted because the following
            resources depend on it:
          </Text>
          <ul>
            {relevantGraph.map((edge) => {
              if (!edge.downstreamType || !edge.downstreamId) return null; // shouldn't happen - appease TS

              const apiResourceName =
                edge.downstreamType.toLowerCase() as ResourceType;

              return (
                <li key={edge.downstreamId}>
                  <ResourceLink
                    resource={{
                      id: edge.downstreamId,
                      resource_type: apiResourceName,
                      name: edge.downstreamLiterate,
                    }}
                    upstreamId={edge.upstreamId}
                    showResourceIcon
                  />
                </li>
              );
            })}
          </ul>
        </>
      )}
    </ModalV2>
  );
}
