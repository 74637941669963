import { useRoute, useRouter } from "react-router5";

import { ROUTE_NAMES } from "../../constants/routeNames";
import {
  useRecommenderShowSuspenseQuery,
  useRecommenderUpdateMutation,
} from "../../hooks/api";
import { useApiErrorToaster } from "../../hooks/useApiErrorToaster";
import {
  HUBSPOT_USER_UPDATED_RECOMMENDER,
  useHubSpotEvent,
} from "../../hooks/useHubspotEvent";
import { useToast } from "../../hooks/useToast";
import {
  RecommenderFormStateToWire,
  RecommendersForm,
} from "./RecommendersForm";
import { RecommendersSidebarLayout } from "./RecommendersSidebarLayout";

export function RecommendersEditPage() {
  const router = useRouter();
  const { route } = useRoute();
  const toast = useToast();
  const track = useHubSpotEvent();

  const id = route.params.id;

  const recommenderQuery = useRecommenderShowSuspenseQuery(id);

  const updateRecommenderMutation = useRecommenderUpdateMutation();
  useApiErrorToaster(updateRecommenderMutation.error);

  if (!recommenderQuery.data)
    throw new Error(
      "BUG: No recommender found, but sojourner did not throw an error"
    );

  const existingRecommender = recommenderQuery.data;

  async function handleSave(state: RecommenderFormStateToWire) {
    const updatedRecommender = await updateRecommenderMutation.mutateAsync({
      id: existingRecommender.id,
      data: state,
    });

    track(HUBSPOT_USER_UPDATED_RECOMMENDER, {
      resource_id: updatedRecommender.id,
      resource_name: updatedRecommender.name,
    });

    toast({
      status: "success",
      title: `${updatedRecommender.name} successfully updated!`,
      description: updatedRecommender.preview
        ? "Recommender in preview mode. It will not generate models until enabled."
        : "Recommender has been updated and will rebuild soon if necessary.",
    });

    router.navigate(ROUTE_NAMES.RECOMMENDERS_SHOW, {
      id: updatedRecommender.id,
    });
  }

  const title = `Edit ${existingRecommender.name}`;
  return (
    <RecommendersSidebarLayout
      title={title}
      lastCrumb={{
        label: title,
        routeName: ROUTE_NAMES.RECOMMENDERS_EDIT,
        params: { id: existingRecommender.id },
      }}
    >
      <RecommendersForm
        initialState={existingRecommender}
        onSave={handleSave}
        saving={updateRecommenderMutation.isPending}
      />
    </RecommendersSidebarLayout>
  );
}
