import { Button } from "@chakra-ui/react";
import { useId } from "react";

import { useMarketOpportunityAnalysisCreateMutation } from "../../../../hooks/api";
import { useApiErrorToaster } from "../../../../hooks/useApiErrorToaster";
import { useToast } from "../../../../hooks/useToast";
import { InlineButtons } from "../../../ui/InlineButtons";
import { ModalV2 } from "../../../ui/ModalV2";
import { PipelineFragment as Scope } from "../../__generated__/PipelineFragment";
import {
  MarketAnalysisForm,
  MarketAnalysisFormState,
} from "./MarketAnalysisForm";

export function MarketAnalysisNewModal({
  scope,
  onClose,
}: {
  scope: Scope;
  onClose: () => void;
}) {
  const toast = useToast();
  const formId = useId();
  const createAnalysisMutation = useMarketOpportunityAnalysisCreateMutation();
  useApiErrorToaster(createAnalysisMutation.error);

  const handleSave = async (state: MarketAnalysisFormState) => {
    await createAnalysisMutation.mutateAsync({
      name: state.name,
      scope_id: scope.id,
      outcome_id: state.outcome_id,
      persona_id: state.persona_id ?? undefined,
      persona_set_id: state.persona_set_id ?? undefined,
    });

    toast({
      title: "Analysis requested",
      description:
        "Your analysis will start building soon and will refresh monthly on or around the 1st of each month.",
      status: "success",
    });

    onClose();
  };

  return (
    <ModalV2
      title="New analysis"
      onClose={onClose}
      isOpen
      footer={
        <InlineButtons reverse>
          <Button
            type="submit"
            loadingText="Saving..."
            isLoading={createAnalysisMutation.isPending}
            form={formId}
          >
            Save
          </Button>
          <Button variant="tertiary" onClick={onClose}>
            Cancel
          </Button>
        </InlineButtons>
      }
    >
      <MarketAnalysisForm scope={scope} onSave={handleSave} formId={formId} />
    </ModalV2>
  );
}
