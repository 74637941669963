import { useEffect } from "react";
import { useRouter } from "react-router5";

import { routeNamesToResourceTypes } from "../../constants/routeNames";
import { useAdmin } from "../../hooks/useAdmin";
import { ToastComponentProps, useToast } from "../../hooks/useToast";
import { colors } from "../../styles/chakra-theme-v2";
import { createChiefUrl } from "../../utils/createChiefUrl";
import { singularize } from "../../utils/english";

type EditableToastProps = Pick<ToastComponentProps, "title" | "description">;

/**
 * Router redirects via rendering a component.
 * Defaults to parent route and merging existing params with new params (if passed).
 *
 * @example
 *
 * if(featureEnabled) {
 *   <Redirect routeName={ROUTE_NAMES.NEW_FEATURE} />
 * }
 *
 * return <OldView />
 */
export function Redirect({
  routeName,
  params,
  replace,
  showToast,
  toastProps,
}: {
  routeName?: string;
  params?: Record<string, string | string[]>;
  /** Replace the route state instead of push */
  replace?: boolean;
  showToast?: boolean;
  toastProps?: EditableToastProps;
}): null {
  const toast = useToast();
  const router = useRouter();
  const route = router.getState();
  const { admin } = useAdmin();

  // If routeName is not provided, use the current route name minus the last segment
  routeName =
    routeName ??
    (route.name.includes(".")
      ? route.name.split(".").slice(0, -1).join(".")
      : undefined);

  useEffect(() => {
    if (routeName) {
      router.navigate(
        routeName,
        {
          ...route.params,
          ...params,
        },
        { replace }
      );

      // ----------------
      // Toast
      //
      // route ex:
      //   {
      //     "name": "cohorts.id",
      //     "params": {
      //         "cohort": "adc143e8-7ebf-4dd7-a42b-697fa17a2c57"
      //     },
      //     "path": "/cohorts/adc143e8-7ebf-4dd7-a42b-697fa17a2c57",
      //     "meta": {
      //         "params": {
      //             "cohorts": {},
      //             "cohorts.id": {
      //                 "cohort": "url"
      //             }
      //         },
      //         "id": 1
      //     }
      // }
      if (showToast) {
        const routeNameMatch = route.name?.match(/(\w+)\.id$/);
        const resourceName = singularize(
          routeNameMatch ? routeNameMatch[1] : route.name
        );
        const resourceId =
          // some routes use legacy parameter names, ex persona -> roster
          route.params?.[resourceName] ??
          route.params.id ??
          Object.values(route.params ?? {})[0];

        const firstRoutePart = route.name.split(".")[0];
        const chiefLink = createChiefUrl({
          id: resourceId,
          resource_type: routeNamesToResourceTypes[firstRoutePart],
        });

        if (routeNameMatch) {
          toast({
            duration: admin ? 20000 : 5000,
            title: `You are being redirected to /${routeName}`,
            description: resourceId ? (
              <>
                {resourceName} with id {resourceId} was not found.
                {admin && (
                  <a
                    href={chiefLink}
                    style={{
                      display: "block",
                      marginTop: "1rem",
                      textDecoration: "underline",
                      color: colors.fdy_purple[500],
                    }}
                  >
                    Try viewing in Chief &rarr;
                  </a>
                )}
              </>
            ) : (
              `${resourceName} was not found`
            ),

            ...toastProps,
          });
        }
      }
    }
  }, [routeName]);

  return null;
}
