import { Box, Divider, Stack } from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";

import {
  OutcomeBiasMitigationStrategy,
  PredictionMode,
} from "../../__generated__/sojournerGlobalTypes";
import { useAccountBasics } from "../../hooks/accountConfigHooks";
import { useFormErrors } from "../../hooks/useFormErrors";
import { useUnsavedChangesWarning } from "../../hooks/useUnsavedChangesWarning";
import { AnalyticsStack } from "../ui/Analytics/AnalyticsStack";
import { Button } from "../ui/Button";
import { CardV2 } from "../ui/Card/CardV2";
import { Checkbox } from "../ui/Checkbox";
import { CohortSelect } from "../ui/CohortSelect";
import { Collapsible } from "../ui/Collapsible";
import { FormField } from "../ui/FormField";
import { FormFieldset } from "../ui/FormFieldset";
import { FormFieldStack } from "../ui/FormFieldStack";
import { Input } from "../ui/Input";
import { MutedText } from "../ui/MutedText";
import { RadioGroupV3 } from "../ui/RadioGroupV3/RadioGroupV3";
import { SwitchV2 } from "../ui/Switch/SwitchV2";
import { TraitMultiSelect } from "../ui/TraitMultiSelect";
import { OutcomeFormBias } from "./OutcomeFormBias";
import { PredictorsBlockedProvidersEnum } from "./OutcomesShowPage/OutcomeDataCard";
import { PROTECTED_TRAITS } from "./protected-traits";
import { OutcomeFormState } from "./useUpdateOutcome";

export const formLabels = {
  attainment: "Attainment cohort",
  attrition: "Attrition cohort",
  eligible: "Eligibility cohort",
  providers: "Providers",
  predictors: "Predictors",
  name: "Outcome name",
};

type OutcomeErrors = {
  attainment?: string;
  attrition?: string;
  eligibility?: string;
  name?: string;
};

function validateOutcome(values: OutcomeFormState) {
  const {
    attainment_cohort_id,
    attrition_cohort_id,
    eligible_cohort_id,
    name,
  } = values;

  const errors: OutcomeErrors = {};

  const prefix = "You cannot choose the same cohort for both";

  if (!attainment_cohort_id) {
    errors.attainment = "Please select an attainment cohort";
  }

  if (attainment_cohort_id && attainment_cohort_id === attrition_cohort_id) {
    errors.attrition = `${prefix} attainment and attrition`;
  }

  if (attainment_cohort_id && attainment_cohort_id === eligible_cohort_id) {
    errors.eligibility = `${prefix} attainment and eligibility`;
  }

  if (attrition_cohort_id && attrition_cohort_id === eligible_cohort_id) {
    errors.eligibility = `${prefix} attrition and eligibility`;
  }

  if (!name.trim().length) {
    errors.name = "Please enter a name for this outcome";
  }

  if (name.trim().length > 64) {
    errors.name = "Name must be 64 characters or less";
  }

  return errors;
}

interface OutcomeFormProps {
  initialState?: OutcomeFormState;
  onSave: (formState: OutcomeFormState) => void;
  saving: boolean;
}

export const initialFormState: OutcomeFormState = {
  name: "",
  attainment_cohort_id: "",
  eligible_cohort_id: "",
  attrition_cohort_id: "",
  feature_blocklist: [],
  predictors: {
    blocked: {
      providers: [],
    },
  },
  bias_mitigation: {
    age: OutcomeBiasMitigationStrategy.NONE,
    gender: OutcomeBiasMitigationStrategy.NONE,
  },
  preview: false,
  prediction_mode: PredictionMode.AUTO,
};

/**
 * Sharable state controlled form for creating and editing outcomes.
 */
export function OutcomeForm({
  onSave,
  initialState = initialFormState,
  saving,
}: OutcomeFormProps) {
  const accountBasics = useAccountBasics();

  const { errors, setErrors, refs: errorRefs } = useFormErrors<OutcomeErrors>();

  const { handleSubmit, register, setValue, watch, formState } =
    useForm<OutcomeFormState>({
      defaultValues: initialState,
    });

  const [featureBlocklist, setFeatureBlocklist] = useState<string[]>(
    initialState.feature_blocklist ?? []
  );

  const [blockedProviders, setBlockedProviders] = useState<string[]>(
    initialState.predictors?.blocked?.providers ?? []
  );

  const bias = watch("bias_mitigation");

  // user warning message for unsaved changes
  const { setWarnBeforeNavigate } = useUnsavedChangesWarning({
    state: [formState.isDirty, featureBlocklist, bias],
  });

  function handleFormSubmitWithCustomErrors(values: OutcomeFormState) {
    const errorsTemp = validateOutcome(values);
    setErrors(errorsTemp);

    if (Object.keys(errorsTemp).length) return;

    const formValues = values;
    formValues.feature_blocklist = featureBlocklist;

    formValues.predictors = {
      blocked: {
        providers: blockedProviders,
      },
    };
    onSave(formValues);
    setWarnBeforeNavigate(false);
  }

  function handleProvidersCheck(e: React.ChangeEvent<HTMLInputElement>): void {
    // once the rest of the provider spec is implemented, blocked providers can contain account uuids + "fig"
    if (e.target.checked) {
      setBlockedProviders((state) =>
        state.filter((item) => item !== PredictorsBlockedProvidersEnum.SELF)
      );
    } else {
      setBlockedProviders((state) => [
        ...state,
        PredictorsBlockedProvidersEnum.SELF,
      ]);
    }
  }

  function handleProtectedTraitsCheck(
    e: React.ChangeEvent<HTMLInputElement>
  ): void {
    if (e.target.checked) {
      setFeatureBlocklist([...featureBlocklist, ...PROTECTED_TRAITS]);
    } else {
      setFeatureBlocklist(
        featureBlocklist.filter((item) => !PROTECTED_TRAITS.includes(item))
      );
    }
  }

  return (
    <AnalyticsStack value="new-outcome">
      <form onSubmit={handleSubmit(handleFormSubmitWithCustomErrors)}>
        <Box display="grid" gap={6}>
          <CardV2
            title="What defines this outcome?"
            text="In order to define an outcome, you choose up to 3 groups of people to serve in different roles. Faraday then uses these groups to automatically build and evaluate machine learning models that predict your outcome."
          >
            <FormField
              label={formLabels.eligible}
              helpText="Is it possible for anyone in Faraday's identity graph to attain this outcome? If not, you can restrict eligibility here — only people in this cohort are candidates for this outcome’s attainment."
              error={errors.eligibility}
              ref={errorRefs.eligibility}
            >
              <CohortSelect
                placeholder="Anyone in Faraday's identity graph"
                {...register("eligible_cohort_id")}
                analyticsName="eligible-cohort-select"
              />
            </FormField>
            <Divider />

            <FormField
              label={formLabels.attainment}
              helpText="What does it mean to achieve this outcome? This cohort indicates the “finish line” — people in this cohort are examples of this outcome’s attainment."
              error={errors.attainment}
              ref={errorRefs.attainment}
            >
              <CohortSelect
                {...register("attainment_cohort_id")}
                analyticsName="attainment-cohort-select"
              />
            </FormField>
            <Divider />
            <FormField
              suffix="optional"
              label={formLabels.attrition}
              helpText="Is it possible to explicitly “fail” this outcome? If so, consider choosing a cohort here to indicate this — people in this cohort are counter-examples of this outcome’s attainment. It is relatively uncommon to set this."
              error={errors.attrition}
              ref={errorRefs.attrition}
            >
              <CohortSelect
                {...register("attrition_cohort_id")}
                analyticsName="attrition-cohort-select"
              />
            </FormField>
          </CardV2>
          <CardV2
            title="Data"
            text="Faraday will use the following data to find patterns that predict your outcome."
          >
            <FormFieldStack>
              <FormFieldset
                legend={formLabels.providers}
                hint="Use data from the following selected providers to find patterns:"
              >
                <Stack>
                  {/* 3pd will be optional once rest of predictors spec is implemented */}
                  <Checkbox
                    isDisabled
                    isChecked={true}
                    analyticsName="uses-3pd"
                  >
                    Faraday's built-in consumer data
                  </Checkbox>
                  <Checkbox
                    onChange={handleProvidersCheck}
                    isChecked={!blockedProviders.includes("self")} //if it doesnt include self, then allow 1pd
                    analyticsName="uses-1pd"
                  >
                    {accountBasics.name} data
                  </Checkbox>
                </Stack>
              </FormFieldset>
              <FormFieldset
                legend={formLabels.predictors}
                hint="Provides control over the features used during modeling to predict an outcome."
              >
                <FormField label="Excluded traits">
                  <TraitMultiSelect
                    onChange={(values) => {
                      setFeatureBlocklist(values);
                    }}
                    value={featureBlocklist}
                  />
                </FormField>

                <Checkbox
                  isChecked={PROTECTED_TRAITS.every((t) =>
                    featureBlocklist.includes(t)
                  )}
                  isIndeterminate={
                    PROTECTED_TRAITS.some((t) =>
                      featureBlocklist.includes(t)
                    ) &&
                    !PROTECTED_TRAITS.every((t) => featureBlocklist.includes(t))
                  }
                  onChange={handleProtectedTraitsCheck}
                  mt={4}
                  analyticsName="excludes-protected-traits"
                  alignItems="flex-start"
                >
                  <strong>Exclude protected traits</strong> gender, age, marital
                  status, etc.
                  <br />
                  <MutedText>
                    You can also review and correct bias after your outcome is
                    created
                  </MutedText>
                </Checkbox>
              </FormFieldset>
            </FormFieldStack>
          </CardV2>

          <OutcomeFormBias
            bias={bias}
            onChange={(val) => setValue("bias_mitigation", val)}
          />

          <CardV2>
            <FormField
              label={formLabels.name}
              helpText={
                <>
                  A strong outcome name is descriptive of its intent, and might
                  be a summary of the eligibility, attainment, and attrition
                  cohorts, such as "Lead scoring with leads from HubSpot" or
                  "Lead scoring for direct mail."
                </>
              }
              error={errors.name}
              ref={errorRefs.name}
            >
              <Input
                {...register("name")}
                autoComplete="off"
                analyticsName="name"
              />
            </FormField>
          </CardV2>

          <CardV2>
            <Collapsible title="Advanced">
              <FormFieldStack>
                <FormField
                  label="Preview"
                  helpText="An outcome in preview mode prevents models from building. Use this if you want to create a draft of configuration."
                >
                  <SwitchV2
                    label="Enable preview mode"
                    isChecked={watch("preview")}
                    onChange={(e) => setValue("preview", e.target.checked)}
                  />
                </FormField>

                <FormField
                  label="Prediction mode"
                  helpText="Defaults to auto, which enables dynamic modeling if possible. The other option is static, which disables dynamic modeling. Dynamic modeling considers chronology and uses advanced feature engineering whenever possible. You may want to disable dynamic prediction if chronology could confuse your use case, or if advised by Faraday staff."
                >
                  <RadioGroupV3.Root
                    value={watch("prediction_mode")}
                    onChange={(value) =>
                      setValue("prediction_mode", value as PredictionMode)
                    }
                  >
                    <RadioGroupV3.Item
                      label="Automatic"
                      value={PredictionMode.AUTO}
                    />
                    <RadioGroupV3.Item
                      label="Static"
                      value={PredictionMode.STATIC}
                    />
                  </RadioGroupV3.Root>
                </FormField>
              </FormFieldStack>
            </Collapsible>
          </CardV2>

          <Button
            type="submit"
            disabled={saving}
            size="lg"
            isLoading={saving}
            loadingText="Saving outcome..."
            width="full"
            analyticsName="save"
          >
            Save outcome
          </Button>
        </Box>
      </form>
    </AnalyticsStack>
  );
}
