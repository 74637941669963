import { ButtonGroup } from "@chakra-ui/react";
import { useRoute, useRouter } from "react-router5";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { useStreamShowSuspenseQuery } from "../../../hooks/api";
import { AlertStack } from "../../ui/AlertStack";
import { CardStack } from "../../ui/CardStack";
import { DependencyStatusAlert } from "../../ui/DependencyStatusAlert";
import { PageContainer } from "../../ui/PageContainer";
import { PageHeader } from "../../ui/PageHeader";
import { PageLayout } from "../../ui/PageLayout";
import { ResourceDocsLink } from "../../ui/ResourceDocsLink";
import { ResourceGraphCard } from "../../ui/ResourceGraphCard";
import { ResourceStatusAlert } from "../../ui/ResourceStatus/ResourceStatusAlert";
import { EventsActionsMenu } from "../EventsActionsMenu";
import { EventStreamAnalysisCard } from "./EventStreamAnalysisCard";
import { EventStreamDatasetContributionCard } from "./EventStreamDatasetContributionCard";
import { EventStreamPropertiesCard } from "./EventStreamPropertiesCard";
import { EventStreamSummaryCard } from "./EventStreamSummaryCard";

export function EventsShowPage() {
  const { route } = useRoute();
  const { navigate } = useRouter();
  const { data: stream } = useStreamShowSuspenseQuery(route.params.id);

  const actions = (
    <ButtonGroup>
      <ResourceDocsLink resourceType="events" size="sm" />
      <EventsActionsMenu
        event={stream}
        onDeleteComplete={() => navigate(ROUTE_NAMES.EVENTS)}
      />
    </ButtonGroup>
  );

  return (
    <PageLayout>
      <PageHeader
        title={stream.name}
        backBtnProps={{
          label: "Events",
          routeName: ROUTE_NAMES.EVENTS,
        }}
        crumbs={[
          {
            label: "Events",
            routeName: ROUTE_NAMES.EVENTS,
          },
          {
            label: stream.name,
            routeName: ROUTE_NAMES.EVENTS_SHOW,
            params: { id: stream.id },
          },
        ]}
        rightContent={actions}
      />
      <PageContainer>
        <AlertStack>
          <DependencyStatusAlert resource={stream} />
          <ResourceStatusAlert resource={stream} />
        </AlertStack>

        <CardStack>
          <EventStreamSummaryCard stream={stream} />
          <EventStreamAnalysisCard stream={stream} />
          <EventStreamDatasetContributionCard stream={stream} />
          <EventStreamPropertiesCard stream={stream} />
          <ResourceGraphCard resourceId={stream.id} />
        </CardStack>
      </PageContainer>
    </PageLayout>
  );
}
