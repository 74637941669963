import { ResourceType } from "@fdy/faraday-js";
import {
  Database,
  Export,
  FunnelSimple,
  Icon,
  MapPin,
  Path,
  Plug,
  Rainbow,
  Shapes,
  Stack,
  Storefront,
  TreeStructure,
  UserCircle,
  Users,
} from "@phosphor-icons/react";

export const ResourceIcon: Record<ResourceType, Icon> = {
  cohorts: Users,
  connections: Plug,
  datasets: Database,
  streams: Stack,
  outcomes: Path,
  persona_sets: Rainbow,
  scopes: TreeStructure,
  traits: FunnelSimple,
  recommenders: Shapes,
  places: MapPin,
  targets: Export,
  accounts: UserCircle,
  market_opportunity_analyses: Storefront,
};
