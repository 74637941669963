import { Box, Text } from "@chakra-ui/react";
import { Upload } from "@phosphor-icons/react";
import { ChangeEvent, ReactElement, useState } from "react";

import { DropZone } from "../../ui/DropZone/DropZone";
import { Input } from "../../ui/Input";

/**
 * Given a browser `File` object, determine Faraday supports uploading the type of file
 */
function isUploadable(file: File): boolean {
  return Boolean(
    file &&
      (file.name.match(/\.csv/i) ||
        file.name.match(/\.txt/i) ||
        file.name.match(/\.gpg/i) ||
        file.name.match(/\.pgp/i))
  );
}

export enum UploadState {
  idle,
  uploading,
  done,
  error,
}

interface UploadDropZoneProps {
  /** A function that handes the files that are dropped */
  onFilesChange: (files: FileList | null) => void;

  /** If the upload is idle or uploading */
  uploadStatus: UploadState;
}

function getFileSize(size: number) {
  if (size >= 1024 * 1024 * 1024) {
    return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
  }
  return `${(size / (1024 * 1024)).toFixed(2)} MB`;
}

/**
 * A drag and drop area that handles file uploads.
 */
export function DatasetUploadDropZone({
  onFilesChange,
  uploadStatus,
}: UploadDropZoneProps): ReactElement {
  const [fileList, setFileList] = useState<FileList | null>(null);

  function handleDropFiles(files: FileList) {
    for (const file of files) {
      if (isUploadable(file)) {
        onFilesChange(files);
        setFileList(files);
      }
    }
  }

  function handleUploadFiles(e: ChangeEvent<HTMLInputElement>) {
    onFilesChange(e.target.files);
    setFileList(e.target.files);
  }

  function getHelperText(uploadStatus: UploadState) {
    if (uploadStatus === UploadState.done) return "Upload successful";
    if (uploadStatus === UploadState.uploading) return "Uploading file...";
    if (uploadStatus === UploadState.error)
      return "Error uploading file. Please refresh the window and try again.";

    return (
      <>
        Drag & drop a CSV file here, or click to upload
        <br />
        <Text color="fdy_gray.700" fontSize="sm">
          Max file size 5GB - Max 50 columns recommended
        </Text>
      </>
    );
  }

  const file = fileList?.length ? fileList[fileList.length - 1] : null;
  const filename = file?.name;

  return (
    <label htmlFor="file-upload">
      <DropZone onFileDrop={handleDropFiles}>
        <Box textAlign="center">
          <Upload style={{ display: "inline-block" }} />
          <Text fontWeight="bold">Upload a file</Text>
          <Text mb={1}>{getHelperText(uploadStatus)}</Text>
          {filename ? (
            <>
              <Text>
                {filename} ({getFileSize(file.size)})
              </Text>
            </>
          ) : null}
          <Input
            display="none"
            id="file-upload"
            type="file"
            onChange={handleUploadFiles}
            accept=".csv,.txt,.CSV,.TXT,.gpg,.GPG,.pgp,.PGP"
            multiple={false}
            disabled={uploadStatus === UploadState.uploading}
            analyticsName="dataset-upload"
          />
        </Box>
      </DropZone>
    </label>
  );
}
