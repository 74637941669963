import { Grid, Tooltip } from "@chakra-ui/react";
import { ResourceType } from "@fdy/faraday-js";
import { LockSimple } from "@phosphor-icons/react";
import upperFirst from "lodash/upperFirst";
import { Dispatch, useCallback, useMemo } from "react";
import * as React from "react";
import { FormatOptionLabelMeta } from "react-select";

import { gqlTypenameToResourceType } from "../../../constants/gqlTypenameToResourceType";
import { resourceTypeAlias } from "../../../constants/typeAlias";
import { AccountConfigMap } from "../../../hooks/accountConfigHooks";
import { CardV2 } from "../../ui/Card/CardV2";
import { Checkbox } from "../../ui/Checkbox";
import {
  FdyReactSelect,
  FdySelectGroupBase,
  FdySelectOption,
} from "../../ui/FdyReactSelect";
import { FormField } from "../../ui/FormField";
import { FormFieldset } from "../../ui/FormFieldset";
import { IconWithText } from "../../ui/IconWithText";
import { ResourceIcon } from "../../ui/ResourceIcon";
import { useId } from "../../ui/useId";
import { scopeInfo } from "../scopeInfo";
import { PipelineFormQuery } from "./__generated__/PipelineFormQuery";
import { FeatureLockedNotice } from "./FeatureLockedNotice";
import { PipelineFormState } from "./PipelineForm";
import { PipelineFormPayloadTraits } from "./PipelineFormPayloadTraits";
import { pipelineFormLabels, sortByLabel } from "./pipelineFormUtils";
import { pipelineHelpText } from "./pipelineHelpText";
import { ResetScopeError, ScopeErrors } from "./usePipelineValidator";

interface PayloadOption extends FdySelectOption {
  resourceType: ResourceType;
}

interface PayloadOptionGroup extends FdySelectGroupBase<PayloadOption> {
  resourceType: ResourceType;
}

export type SetPiplineFormState = Dispatch<
  React.SetStateAction<PipelineFormState>
>;

interface PipelineFormPayloadCardProps {
  data: PipelineFormQuery | undefined;
  formState: PipelineFormState;
  setFormState: SetPiplineFormState;
  resetError: ResetScopeError;
  errors: ScopeErrors | undefined;
  configMap: AccountConfigMap;
  loading: boolean;
}

export function LockedFeatureLabel({
  label,
  locked,
}: {
  label: string;
  locked: boolean;
}) {
  if (!locked) return <>{label}</>;

  return (
    <IconWithText>
      {label}
      <Tooltip label="Contact support to enable">
        <LockSimple />
      </Tooltip>
    </IconWithText>
  );
}

function makeOptionGroup(
  resources: {
    __typename: string;
    id: string;
    name: string;
    archivedAt: string | null;
  }[],
  disabled?: boolean
): PayloadOptionGroup {
  const resourceType = gqlTypenameToResourceType[resources[0]?.__typename];

  if (!resourceType) throw new Error("Invalid resource type");

  return {
    label: upperFirst(resourceTypeAlias(resourceType)),
    icon: ResourceIcon[resourceType],
    resourceType,
    options: resources
      .filter((r) => {
        return !r.archivedAt;
      })
      .map((r) => ({
        value: r.id,
        label: r.name,
        disabled,
        resourceType,
      }))
      .sort(sortByLabel),
  };
}

function pipelineResourcesToPayloadGroups(
  data: PipelineFormQuery
): PayloadOptionGroup[] {
  const { outcomes, personaSets, recommenders } = data;

  const groups = [];

  if (outcomes.length) groups.push(makeOptionGroup(outcomes));
  if (personaSets.length) groups.push(makeOptionGroup(personaSets));
  if (recommenders.length) groups.push(makeOptionGroup(recommenders));

  return groups;
}

function PayloadScoreExplainability({
  configMap,
  formState,
  setFormState,
}: {
  configMap: AccountConfigMap;
  formState: PipelineFormState;
  setFormState: Dispatch<React.SetStateAction<PipelineFormState>>;
}) {
  const disabled = configMap["scopes.explainability_enabled"] !== true;

  function handleChange() {
    setFormState((state) => ({
      ...state,
      outcomeExplainability: !state.outcomeExplainability,
    }));
  }

  return (
    <FormFieldset
      size="sm"
      legend={
        <LockedFeatureLabel label="Prediction explanations" locked={disabled} />
      }
      hint={pipelineHelpText.predictionExplainations}
    >
      <Checkbox
        onChange={handleChange}
        isChecked={formState.outcomeExplainability}
        isDisabled={disabled || formState.payloadOutcomeIds.length === 0}
        analyticsName="include-prediction-explanations"
      >
        Include prediction explanations
      </Checkbox>
    </FormFieldset>
  );
}

function PredictionOptionLabel(
  item: PayloadOption,
  meta: FormatOptionLabelMeta<PayloadOption>
) {
  const Icon = ResourceIcon[item.resourceType];
  if (meta.context === "menu") {
    return item.label;
  }

  return (
    <IconWithText>
      <Icon />
      {item.label} - {resourceTypeAlias(item.resourceType)}
    </IconWithText>
  );
}

function PayloadPredictions({
  data,
  errors,
  configMap,
  setFormState,
  formState,
  resetError,
  loading,
}: {
  loading: boolean;
  data: PipelineFormQuery | undefined;
  setFormState: SetPiplineFormState;
  formState: PipelineFormState;
  configMap: AccountConfigMap;
  errors?: ScopeErrors;
  resetError: ResetScopeError;
}) {
  const id = useId();

  /**
   * Convert the pipeline resources into the format expected by the multiselect component.
   */
  const payloadGroups = useMemo<PayloadOptionGroup[]>(() => {
    if (!data) return [];
    return pipelineResourcesToPayloadGroups(data);
  }, [data]);

  /**
   * Handles the change of the payload content.
   *
   * We get multiselect items from the payload select and then we need to convert
   * them to the format expected by the local state i.e. just a list of UUIDs for each group.
   */
  const handleSelectOptionsChange = useCallback(
    (selectedOptions: readonly PayloadOption[]) => {
      const outcomes: string[] = [];
      const personaSets: string[] = [];
      const recommenders: string[] = [];

      for (const item of selectedOptions) {
        if (item.resourceType === ResourceType.Outcomes) {
          outcomes.push(item.value);
        }
        if (item.resourceType === ResourceType.PersonaSets) {
          personaSets.push(item.value);
        }
        if (item.resourceType === ResourceType.Recommenders) {
          recommenders.push(item.value);
        }
      }

      setFormState((state) => ({
        ...state,
        payloadOutcomeIds: outcomes,
        payloadPersonaSetIds: personaSets,
        payloadRecommenderIds: recommenders,
        outcomeExplainability:
          outcomes.length > 0 ? state.outcomeExplainability : false,
      }));

      resetError("predictions");
      resetError("cohortMembership");
      resetError("traits");
    },
    [setFormState, resetError]
  );

  /**
   * Convert the selected form state values into the list of multi select items,
   * since we pass those as the value to the select items.
   */
  const payloadValue = useMemo<PayloadOption[]>(() => {
    const allOptions = payloadGroups.flatMap((g) => g.options);

    return allOptions.filter((o) => {
      if (o.resourceType === ResourceType.Outcomes) {
        return formState.payloadOutcomeIds.includes(o.value);
      }
      if (o.resourceType === ResourceType.PersonaSets) {
        return formState.payloadPersonaSetIds.includes(o.value);
      }
      if (o.resourceType === ResourceType.Recommenders) {
        return formState.payloadRecommenderIds.includes(o.value);
      }
      return false;
    });
  }, [
    payloadGroups,
    formState.payloadOutcomeIds,
    formState.payloadPersonaSetIds,
    formState.payloadRecommenderIds,
  ]);

  return (
    <FormField
      htmlFor={id}
      label={pipelineFormLabels.predictions}
      error={errors?.predictions}
      helpText={pipelineHelpText.payload(configMap)}
      analyticsName="predictions"
    >
      <FdyReactSelect<PayloadOption, true, PayloadOptionGroup>
        inputId={id}
        isMulti
        isLoading={loading}
        isDisabled={loading}
        options={payloadGroups}
        value={payloadValue}
        formatOptionLabel={PredictionOptionLabel}
        onChange={handleSelectOptionsChange}
        placeholder="Select predictions..."
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        isInvalid={Boolean(errors?.predictions)}
      />
    </FormField>
  );
}

function PayloadCohortMembership({
  data,
  errors,
  configMap,
  setFormState,
  formState,
  resetError,
  loading,
}: {
  loading: boolean;
  data: PipelineFormQuery | undefined;
  setFormState: SetPiplineFormState;
  formState: PipelineFormState;
  configMap: AccountConfigMap;
  errors?: ScopeErrors;
  resetError: ResetScopeError;
}) {
  const id = useId();
  const disabled = configMap["scopes.cohort_membership_allowed"] !== true;

  function handleChange(selectedOptions: readonly PayloadOption[]) {
    setFormState(
      (state): PipelineFormState => ({
        ...state,
        payloadCohortIds: selectedOptions.map((opt) => opt.value),
      })
    );

    resetError("predictions");
    resetError("cohortMembership");
    resetError("traits");
  }

  const cohortOptions = data?.cohorts
    .filter((c) => {
      return !c.archivedAt || formState.payloadCohortIds.includes(c.id);
    })
    .map((c) => ({
      label: c.name,
      value: c.id,
      resourceType: ResourceType.Cohorts,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const value = cohortOptions?.filter((c) =>
    formState.payloadCohortIds.includes(c.value)
  );

  return (
    <FormField
      htmlFor={id}
      label={
        <LockedFeatureLabel
          label={pipelineFormLabels.cohortMembership}
          locked={disabled}
        />
      }
      helpText={pipelineHelpText.cohortMembership}
      error={errors?.cohortMembership}
      analyticsName="cohort-membership"
    >
      <FdyReactSelect<PayloadOption, true>
        inputId={id}
        isMulti
        isLoading={loading}
        isDisabled={loading || disabled}
        options={cohortOptions}
        value={value}
        onChange={handleChange}
        placeholder="Select membership cohorts..."
        isInvalid={Boolean(errors?.cohortMembership)}
      />
    </FormField>
  );
}

export function PipelineFormPayloadCard({
  data,
  formState,
  setFormState,
  resetError,
  errors,
  configMap,
  loading,
}: PipelineFormPayloadCardProps) {
  return (
    <CardV2
      title="Payload"
      text={scopeInfo.payload}
      analyticsStackName="payload"
    >
      <Grid gap={6}>
        <PayloadPredictions
          loading={loading}
          formState={formState}
          setFormState={setFormState}
          data={data}
          configMap={configMap}
          errors={errors}
          resetError={resetError}
        />

        <PayloadCohortMembership
          loading={loading}
          formState={formState}
          setFormState={setFormState}
          data={data}
          configMap={configMap}
          errors={errors}
          resetError={resetError}
        />

        <PipelineFormPayloadTraits
          formState={formState}
          setFormState={setFormState}
          errors={errors}
          resetError={resetError}
        />

        <PayloadScoreExplainability
          configMap={configMap}
          formState={formState}
          setFormState={setFormState}
        />

        <FeatureLockedNotice
          unlessEnabled={[
            "scopes.cohort_membership_allowed",
            "scopes.explainability_enabled",
          ]}
        />
      </Grid>
    </CardV2>
  );
}
