import { ReactElement, useEffect } from "react";
import { useRoute } from "react-router5";

import { ResourceStatus } from "../../../__generated__/sojournerGlobalTypes";
import { useAccountConfigMap } from "../../../hooks/accountConfigHooks";
import { useFlags } from "../../../hooks/useFlags";
import { AlertStack } from "../../ui/AlertStack";
import { AnimatedZapLogo } from "../../ui/AnimatedZapLogo";
import { CardV2 } from "../../ui/Card/CardV2";
import { CardStack } from "../../ui/CardStack";
import { DependencyStatusAlert } from "../../ui/DependencyStatusAlert";
import { Notice } from "../../ui/Notice";
import { ResourceGraphCard } from "../../ui/ResourceGraphCard";
import { ResourceProgressBar } from "../../ui/ResourceProgressBar";
import { ResourceArchivedAlert } from "../../ui/ResourceStatus/ResourceArchivedAlert";
import { ResourceStatusAlert } from "../../ui/ResourceStatus/ResourceStatusAlert";
import { PipelinesShowPageQuery_scope as Scope } from "../__generated__/PipelinesShowPageQuery";
import { PipelineDeploymentCard } from "../deployment/PipelineDeploymentCard";
import { PipelineLayout } from "../PipelineLayout";
import { PipelineRecipeNotice } from "../PipelineRecipeNotice";
import { usePipelineQuery } from "../usePipelineQuery";
import { PipelineDiagnosticsCard } from "./PipelineDiagnosticsCard";
import { PipelineEfficacyCard } from "./PipelineEfficacyCard";
import { PipelineMarketOpportunityCard } from "./PipelineMarketOpportunityCard";
import { PipelinePayloadCard } from "./PipelinePayloadCard";
import { PipelinePopulationCard } from "./PipelinePopulationCard";

// if scope is built but no targets yet, let them know they need to add a target
// before their scope is truly effective
function PipelineStatus({ scope }: { scope: Scope }) {
  if (
    scope.targets.length === 0 &&
    scope.lastUpdatedOutputAt &&
    scope.status !== ResourceStatus.ERROR
  ) {
    return (
      <Notice
        variant="success"
        title="Pipeline defined!"
        description="Everything looks good and is ready to go. You just need to choose where
        you’d like to deploy your predictions to!"
        dismissable
      />
    );
  }

  // TODO: make resource status fetch dependencies on its own
  return (
    <ResourceStatusAlert
      resource={scope}
      titlePrefix={scope.preview ? "Preview" : undefined}
    />
  );
}

function PipelineProgressbar({ scope }: { scope: Scope }) {
  return (
    <CardV2
      text="Faraday is building your pipeline, which will take a moment. Feel free
        to leave this page: we’ll email you when done. You’ll still need to
        choose deployments and activate the pipeline."
    >
      <ResourceProgressBar status={scope.status} />
    </CardV2>
  );
}

export function PipelinesShowPage(): ReactElement {
  const { route } = useRoute();
  const scopeId = route.params.id;

  const { flags, demoOrDevEnv } = useFlags();
  const accountConfig = useAccountConfigMap();

  const { data, loading, error, stopPolling } = usePipelineQuery(scopeId, {
    pollInterval: 1000,
  });

  useEffect(() => {
    if (data?.scope?.populationCount !== null) {
      stopPolling();
    }
  }, [data]);

  if (error) throw error;
  if (loading) return <AnimatedZapLogo />;
  const scope = data?.scope;
  if (!scope) {
    throw new Error(
      `BUG: No scope found, but sojourner did not throw an error`
    );
  }

  return (
    <PipelineLayout scope={scope} title={scope.name}>
      <AlertStack>
        <ResourceArchivedAlert resource={scope} />
        <DependencyStatusAlert resource={scope} />
        <PipelineStatus scope={scope} />
      </AlertStack>
      <CardStack>
        {route.params.recipe && <PipelineRecipeNotice />}
        {scope.lastUpdatedOutputAt === null && (
          <PipelineProgressbar scope={scope} />
        )}
        <PipelinePopulationCard scope={scope} />
        <PipelinePayloadCard scope={scope} />
        <PipelineDiagnosticsCard scope={scope} />
        {(flags.scope_efficacy || demoOrDevEnv) && (
          <PipelineEfficacyCard scope={scope} />
        )}
        {(accountConfig["market_opportunity_analyses.enabled"] ||
          demoOrDevEnv) && <PipelineMarketOpportunityCard scope={scope} />}
        <PipelineDeploymentCard scope={scope} />
        <ResourceGraphCard resourceId={scope.id} />
      </CardStack>
    </PipelineLayout>
  );
}
